import React, { useEffect } from 'react'

import { useToken } from 'app/hooks'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { useUserSocket } from 'app/hooks/trade/userSocket'
import DarkModeToggle from 'app/organisms/Header/DarkModeToggle/mobile'
import styled from 'styled-components'
import { MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { Text, View } from '../../../styles/reactnative'
import GroupFilter from './GroupFilter'
import MyInfo from './MyInfo'
import { PartnerRealtimeDrag } from './PartnerRealtimeDrag'
import RealTimeTopNav from './RealTimeTopNav'

export function PartnerRealtime({ modalshow = false, setModalshow = e => {} }) {
	const { data: token } = useToken()

	const [group, setGroup] = React.useState<any>('*')

	const [onCustomer, setOnCustomer] = React.useState(true)
	const [onPosition, setOnPosition] = React.useState(true)
	const [onWait, setOnWait] = React.useState(true)

	const [scale, setScale] = React.useState(1)

	useEffect(() => {
		const savedOnOff = localStorage.getItem('partner-realtime-onoff')
		if (savedOnOff) {
			const onoff = JSON.parse(savedOnOff)
			setOnCustomer(onoff.customer)
			setOnPosition(onoff.position)
			setOnWait(onoff.wait)
			setScale(onoff.scale || 1)
		}
	}, [])

	const { theme } = React.useContext(ThemeContext)
	const { change } = useTradeModalStore()
	const socket = useUserSocket()

	useEffect(() => {
		change({
			partner: 1,
		})

		return () => {
			change({
				partner: 0,
			})
		}
	}, [])

	return (
		<RealtimeWrapper
			style={{
				width: '100%',
				gap: 10,
				backgroundColor: theme.COLORS.background,
			}}>
			{!token && (
				<MView>
					<Text>로그인 후 이용 가능합니다.</Text>
				</MView>
			)}

			{token && (
				<>
					<View
						style={{
							flexDirection: 'column',
							width: '100%',
							height: 0,
						}}>
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								position: 'fixed',
								backgroundColor: theme.COLORS.bgColor,
								borderBottom: `1px solid ${theme.COLORS.borderColor}`,
								color: theme.COLORS.textw,
								height: 40,
								width: '100%',
								zIndex: 1000,
								top: 0,
								left: 0,
							}}>
							<View style={{ flexDirection: 'row', gap: 10 }}>
								<Text
									style={{
										fontWeight: 'bold',
										fontSize: 16,
										marginLeft: 10,
									}}>
									실시간 현황
								</Text>
								<View style={{ flexDirection: 'row', gap: 10 }}>
									<RealTimeTopNav
										onCustomer={onCustomer}
										onPosition={onPosition}
										onWait={onWait}
										setOnCustomer={setOnCustomer}
										setOnPosition={setOnPosition}
										setOnWait={setOnWait}
										scale={scale}
										setScale={setScale}
									/>

									<GroupFilter
										group={group}
										setGroup={setGroup}
									/>
								</View>
							</View>
							<View style={{ flexDirection: 'row', gap: 10 }}>
								<MyInfo />
								<DarkModeToggle />
							</View>
						</View>
					</View>

					<div style={{ height: 30 }}></div>
					<PartnerRealtimeDrag
						group={group}
						onCustomer={onCustomer}
						onPosition={onPosition}
						onWait={onWait}
						scale={scale}
					/>
					{/* 					
					<a id="customer"></a>
					<div style={{ height: 15 }}></div>
					<PartnerRealtimeCustomer group={group} />
					<a id="position"></a>
					<div style={{ height: 15 }}></div>
					<PartnerRealtimePosition group={group} />
					<a id="wait"></a>
					<div style={{ height: 15 }}></div>
					<PartnerRealtimeWait group={group} /> */}
					{/* <a id="realtime"></a>
					<div style={{ height: 15 }}></div>
					<PartnerRealtimeExchange group={group} /> */}
				</>
			)}
			{/* 
			<Modal
				open={modal}
				onCancel={() => setModal(false)}
				width={'100%'}
				style={{ width: '90%' }}>
				<UserContent
					showOpen={modal}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					defaultfold={selectedData ? false : true}
				/>
			</Modal> */}
		</RealtimeWrapper>
	)
}

export const RealtimeWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
	padding: 0;
	height: 100%;
	overflow: auto;
	background-color: ${props => props.theme.COLORS.background};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	& .ant-card-head {
		border: 0px !important;

		background: ${props => props.theme.COLORS.descBox} !important;
	}

	& .ant-card {
		background-color: ${props => props.theme.COLORS.bgColor} !important;
		border: 0px !important;
	}

	& .ant-card-head-title {
		color: ${props => props.theme.COLORS.textw} !important;
	}

	& .ant-space-item {
		color: ${props => props.theme.COLORS.textw};
	}

	& .ant-card-body div {
		color: ${props => props.theme.COLORS.textw};
	}
`

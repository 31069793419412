import { Space, Switch } from 'antd'
import React from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const OnlineFilter = ({
	onlyOnline, setOnlyOnline,
}) => {
	const { theme } = React.useContext(ThemeContext)
	return (
		<View
			style={{
				backgroundColor: theme.COLORS.descBox,
				flexDirection: 'row',
				padding: '2px 2px',
				borderRadius: 5,
				gap: 2,
			}}>
			<Text
				style={{
					fontWeight: 'bold',
					fontSize: 11,
					color: theme.COLORS.textw,
					backgroundColor: theme.COLORS.descBox,
					borderRadius: 5,
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					padding: '4px 2px',
				}}>
				접속자만
			</Text>
			<View
				style={{
					flexDirection: 'row',
					color: theme.COLORS.textw,
					backgroundColor: theme.COLORS.background,
					borderRadius: 5,
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					padding: '2px 2px',
				}}>
				<Space.Compact
					size="small"
					style={{
						alignItems: 'center',
						whiteSpace: 'nowrap',
						fontSize: 10,
					}}>
					<Switch
						onChange={e => setOnlyOnline(e)}
						defaultChecked={onlyOnline}
					/>
				</Space.Compact>
			</View>
		</View>
	)
}

export default React.memo(OnlineFilter)

export const COLORS = {
	textLightGrey: '#A59C9C',
	textLightGray: '#A59C9C',
	textDarkGray: '#A59C9C',
	textDarkGrey: '#A59C9C',
	darkBg: '#222222',
	drawerBg: '#2b2b2b',
	darkBox: '#343434',
	darkBoxOpacity: '#34343422',
	lightBg: '#FDFFF0',
	white: '#f8f8f8',
	whiteOpacity: 'rgba(255, 255, 255, 0.8)',
	blackOpacity: 'rgba(0, 0, 0, 0.8)',
	blackCardOpacity: 'rgba(0,0,0,0.4)',
	whiteCardOpacity: 'rgba(255,255,255,0.4)',
	black: '#000000',
	darkGray: '#000000',
	point: '#3a23ec',
	darkGrey: '#333333',
	lightGrey: '#FAEEEE',
	lightGray: '#eee',
	lightSilver: '#BDBDBD',
	onSecondary: '#EDEFF1',
	complementary: '#ffffff',
	darkBorder: '#ddd',
	lightBorder: '#444',
	subHeaderDark: '#303030',
	subHeaderLight: '#FBFBFB',
	shadowDark: '#444',
	shadowLight: '#e5e5e5',
}
export const theme: {
	light: { COLORS: any }
	dark: { COLORS: any }
} = {
	light: {
		COLORS: {
			background: COLORS.darkBg,
			bgColor: COLORS.black,
			drawerBgColor: COLORS.drawerBg,
			mdrawerBgColor: 'rgba(0,0,0,0.5)',
			tradeBG: '#121212',
			mitBG: '#121212',
			opacityBgColor: COLORS.blackOpacity,
			descBox: COLORS.darkBox,
			textsilver: COLORS.lightSilver,
			gray: COLORS.darkGray,
			grey: COLORS.darkGrey,
			textw: '#F6EDED',
			stroke: '#3C3C39',
			Secondary: '#F6EDED',
			Tertiary: '#EBE2E2',
			onSecondary: '#CBD1D7',
			onTertiary: '#000000',
			fail_sub: '#333A45',
			Prinary: '#ffffff',
			raise_sub: '#604036',
			point: '#009f92',
			complementary: '#1D1D1D',
			fail: '#196FF0',
			raise: '#F05B51',
			textGrey: COLORS.textLightGrey,
			textGray: COLORS.textLightGrey,
			borderColor: COLORS.lightBorder,
			descBorder: COLORS.darkBorder,
			header: COLORS.darkGray,
			subHeader: COLORS.subHeaderDark,
			shadow: COLORS.shadowDark,
			cardOpacity: COLORS.blackCardOpacity,
		},
	},
	dark: {
		COLORS: {
			background: COLORS.white,
			drawerBgColor: COLORS.white,
			mdrawerBgColor: COLORS.white,
			tradeBG: COLORS.white,
			mitBG: '#f3f3f3',
			opacityBgColor: COLORS.whiteOpacity,
			descBox: COLORS.darkBoxOpacity,
			onTertiary: '#E2ECEA',
			fail_sub: '#E0F2FF',
			fail: '#007EDA',
			raise: '#F05B51',
			raise_sub: '#FFEDED',
			bgColor: COLORS.white,
			stroke: '#EAEAEA',
			onSecondary: '#EDEFF1',
			Prinary: '#303030',
			textsilver: COLORS.lightSilver,
			complementary: '#fcfcfc',
			point: '#009f92',
			gray: COLORS.lightGray,
			grey: COLORS.lightGrey,
			textw: '#3A3A3A',
			Tertiary: '#7D7D7D',
			Secondary: '#3A3A3A',
			textGrey: COLORS.textLightGrey,
			textGray: COLORS.textLightGrey,
			borderColor: COLORS.darkBorder,
			descBorder: COLORS.lightBorder,
			header: COLORS.white,
			subHeader: COLORS.subHeaderLight,
			shadow: COLORS.shadowLight,
			cardOpacity: COLORS.whiteCardOpacity,
		},
	},
}

export const NAME = 'NEOM'

import React, { useEffect, useState } from 'react'

import { Space } from 'antd'
import CanvasTableAPI from 'app/components/TableAPI/CanvasTableAPI'
import dayjs from 'dayjs'
import { View } from '../../../styles/reactnative'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import { useUserList } from '../../hooks/trade/userList'
import ExceptBox from './ExceptBox'
import OnlineFilter from './OnlineFilter'
import { PartnerRealtimeCustomerColumn } from './PartnerRealtimeCustomerColumn'
import SearchFilter from './SearchFilter'
import TopSumFixFilter from './TopSumFixFilter'

const PartnerRealtimeCustomer = ({ group, width=100, height=650, scale=1 }) => {
	const [selectedData, setSelectedData] = React.useState<any>(undefined)

	const [topSum, setTopSum] = useState(false)

	const { userlist, sendwho } = useUserList()
	const [level, setLevel] = React.useState<any>('1')

	const [search, setSearch] = React.useState(selectedData || '')
	const [search2, setSearch2] = React.useState(null)

	const [api, setApi] = React.useState<any>(``)

	const { data: coinglobal } = useCoinGlobal()

	const [onlyOnline, setOnlyOnline] = useState(false)

	const now = dayjs()

	const [startDate, setStartDate] = useState<any>(now.format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState<any>(now.format('YYYY-MM-DD'))

	//00시 ~ 06시까지는 전날로 계산s
	useEffect(() => {
		if (now.hour() < 6) {
			setStartDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
			setEndDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
		}
	}, [])

	const [exceptType1, setExceptType1] = useState(true)
	const [exceptType2, setExceptType2] = useState(true)
	const [exceptType3, setExceptType3] = useState(true)
	const [exceptType4, setExceptType4] = useState(true)
	const [exceptType5, setExceptType5] = useState(true)

	const [exceptType, setExceptType] = useState('')
	const [isAll, setIsAll] = useState(true)

	useEffect(() => {
		const url = `/group/balance?startdate=${startDate}&enddate=${endDate}&realtime=1&isall=${isAll}&start
		&t=${(exceptType && `&excepttype=${exceptType}`) || ''}&searchWord=${
			search2 || ''
		}&level=${level}&group=${group}`

		setApi(url)
		sendwho()
	}, [level, search2, exceptType, startDate, endDate, isAll, group])

	useEffect(() => {
		sendwho()
	}, [])

	useEffect(() => {
		let exceptType = ''
		if (exceptType1) exceptType += `${exceptType ? ',' : ''}-2`
		if (exceptType2) exceptType += `${exceptType ? ',' : ''}-1`
		if (exceptType3) exceptType += `${exceptType ? ',' : ''}-9`
		if (exceptType4) exceptType += `${exceptType ? ',' : ''}6`
		if (exceptType5) exceptType += `${exceptType ? ',' : ''}7`
		setExceptType(exceptType)
	}, [exceptType1, exceptType2, exceptType3, exceptType4, exceptType5])

	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				height: '100%',
				justifyContent: 'space-between',
			}}>
			<CanvasTableAPI
				title={'고객관리'}
				defaultMaxData={500}
				height={height}
				width={width}
				scale={scale}
				minusHeight={180}
				autorefresh={60000}
				stylewidth={'100%'}
				className={'plist nopadding'}
				coinglobal={coinglobal}
				loading={false}
				realtime={true}
				topSum={topSum}
				actionrefresh={() => {
					sendwho()
				}}
				extra={
					<Space
						style={{
							whiteSpace: 'nowrap',
							alignContent: 'center',
							justifyItems: 'center',
							justifyContent: 'center',
						}}>
						<TopSumFixFilter topSum={topSum} setTopSum={setTopSum} />

						<OnlineFilter
							onlyOnline={onlyOnline}
							setOnlyOnline={setOnlyOnline}
						/>

						<ExceptBox
							exceptType1={exceptType1}
							setExceptType1={setExceptType1}
							exceptType2={exceptType2}
							setExceptType2={setExceptType2}
							exceptType3={exceptType3}
							setExceptType3={setExceptType3}
							exceptType4={exceptType4}
							setExceptType4={setExceptType4}
							exceptType5={exceptType5}
							setExceptType5={setExceptType5}
						/>

						<SearchFilter
							search={search}
							search2={search2}
							setSearch={setSearch}
							setSearch2={setSearch2}
						/>
					</Space>
				}
				datesearch={false}
				columns={PartnerRealtimeCustomerColumn}
				onlyonline={onlyOnline}
				apikey={api}
				footer={1}
			/>
		</View>
	)
}

export default React.memo(PartnerRealtimeCustomer)

import BigNumber from 'bignumber.js'
import { COINHIDE } from 'app/constants/APIKEYS'
import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import KrwPrice from '../KrwPrice'
const TableFooter = ({
	footer,
	dataSource,
	totalCount,
	fold = false,
	coinglobal = 'G',
}) => {
	const { theme } = useContext(ThemeContext)

	let footerTable: any = null

	if (footer == 9) {
		//long_short = L 인것의 amount 합
		const long_total = dataSource.reduce((acc, curr) => {
			if (curr['long_short'] == 'L') {
				return acc + curr['amount']
			}
			return acc
		}, 0)

		//long_short = S 인것의 amount 합
		const short_total = dataSource.reduce((acc, curr) => {
			if (curr['long_short'] == 'S') {
				return acc + curr['amount']
			}
			return acc
		}, 0)

		footerTable = () => {
			return (
				<View
					style={{
						flexDirection: 'row',
						gap: 10,
						backgroundColor: theme.COLORS.drawerBgColor,
						width: '100%',
						padding: 0,
						justifyContent: 'flex-start',
						alignItems: 'center',
						flex: 1,
					}}>
					<Text
						style={{
							backgroundColor: theme.COLORS.tradeBG,
							fontSize: 11,
						}}>
						전체: {totalCount || 0}
					</Text>
					<Text
						style={{
							backgroundColor: theme.COLORS.tradeBG,
							fontSize: 11,
						}}>
						매수 : {long_total}
					</Text>
					<Text
						style={{
							backgroundColor: theme.COLORS.tradeBG,
							fontSize: 11,
						}}>
						매도 : {short_total}
					</Text>
				</View>
			)
		}
	} else if (footer == 5) {
		footerTable = () => {
			return (
				<View style={{ flexDirection: 'row', gap: 10 }}>
					동결금합 :{' '}
					<KrwPrice
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc).plus(curr['margin']).toNumber(),
							0
						)}
					/>
				</View>
			)
		}
	} else if (footer == 1) {
		footerTable = () => {
			const groupedData = dataSource.reduce((acc, curr) => {
				const userId = curr['id']
				const existingEntry = acc.find(
					entry => entry['user_id'] === userId
				)

				if (existingEntry) {
					// 이미 그룹이 존재하는 경우
					if (curr['daydate'] > existingEntry['daydate']) {
						// 현재 데이터가 더 최근인 경우 값을 업데이트
						existingEntry['last_balance'] = curr['last_balance']
					}
				} else {
					// 그룹이 존재하지 않는 경우 새로운 그룹 생성
					acc.push({
						user_id: userId,
						last_balance: curr['last_balance'],
						daydate: curr['daydate'], // 최근값 비교를 위한 timestamp 추가
					})
				}

				return acc
			}, [])
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						alignItems: 'center',
						flex: 1,
						width: '100%',
					}}>
					<div style={{ width: '100%' }}>
						총갯수: {totalCount || 0}
					</div>

					<View style={{ flexDirection: 'row', gap: 10 }}>
						<View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								일반 입금 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr['total_deposit_amount']
												)
												.toNumber(),
										0
									)}
								/>
							</View>

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								별도 입금 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr[
														'total_deposit_amount_extra'
													]
												)
												.toNumber(),
										0
									)}
								/>
							</View>
							{!COINHIDE ? (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'row',
									}}>
									{'코인>해선 합계:'}
									<KrwPrice
										style={{ width: 150 }}
										price={dataSource.reduce(
											(acc, curr) =>
												BigNumber(acc)
													.plus(
														curr[
															'total_deposit_amount_move'
														]
													)
													.toNumber(),
											0
										)}
									/>
								</View>
							) : (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'row',
									}}>
									-
								</View>
							)}

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
									borderBottom: '1px solid #efefef',
									borderTop: '1px solid #efefef',
								}}>
								{'입금총합 합계:'}
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr[
														'total_deposit_amount_move'
													]
												)
												.plus(
													curr[
														'total_deposit_amount_extra'
													]
												)
												.plus(
													curr['total_deposit_amount']
												)
												.toNumber(),
										0
									)}
								/>
							</View>
						</View>

						<View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								일반 출금 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								별도 출금 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount_extra'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>
							{!COINHIDE ? (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'row',
									}}>
									{'해선>코인 합계:'}
									<KrwPrice
										style={{ width: 150 }}
										price={
											-Math.abs(
												dataSource.reduce(
													(acc, curr) =>
														BigNumber(acc)
															.plus(
																curr[
																	'total_withdraw_amount_move'
																]
															)
															.toNumber(),
													0
												)
											)
										}
									/>
								</View>
							) : (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'row',
									}}>
									-
								</View>
							)}

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
									borderBottom: '1px solid #efefef',
									borderTop: '1px solid #efefef',
								}}>
								{'출금총합 합계:'}
								<KrwPrice
									style={{ width: 150 }}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount_move'
															]
														)
														.plus(
															curr[
																'total_withdraw_amount_extra'
															]
														)
														.plus(
															curr[
																'total_withdraw_amount'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>
						</View>

						<View
							style={{
								borderLeft: '1px solid #ccc',
								paddingLeft: 10,
							}}>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								총매매손익 합:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr['total_pnl_price'] || 0
												)
												.toNumber(),
										0
									)}
								/>
							</View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								총수수료 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													-Math.abs(
														curr['total_fee_price']
													) || 0
												)
												.toNumber(),
										0
									)}
								/>
							</View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								실현손익 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr['total_trade_price'] ||
														0
												)
												.toNumber(),
										0
									)}
								/>
							</View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								마지막잔액 합:
								<KrwPrice
									style={{ width: 150 }}
									price={groupedData.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(curr['last_balance'] || 0)
												.toNumber(),
										0
									)}
								/>
							</View>
						</View>
					</View>
				</div>
			)
		}
	} else if (footer == 2) {
		footerTable = () => {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flex: 1,
						width: '100%',
					}}>
					<div style={{ width: 60 }}>Total: {totalCount || 0}</div>

					{fold && (
						<>
							<KrwPrice
								style={{ width: 130 }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(curr['total_deposit_amount'])
											.toNumber(),
									0
								)}
							/>
							<KrwPrice
								style={{ width: 130 }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(curr['total_withdraw_amount'])
											.toNumber(),
									0
								)}
							/>
						</>
					)}
					<KrwPrice
						style={{ width: 130 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['total_pnl_price'] || 0)
									.toNumber(),
							0
						)}
					/>
					<KrwPrice
						style={{ width: 130 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(
										-Math.abs(curr['total_fee_price']) || 0
									)
									.toNumber(),
							0
						)}
					/>
					<KrwPrice
						style={{ width: 150 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['total_trade_price'] || 0)
									.toNumber(),
							0
						)}
					/>

					<KrwPrice
						style={{ width: 130 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['last_sumbalance'] || 0)
									.toNumber(),
							0
						)}
					/>
				</div>
			)
		}
	} else if (footer == 3) {
		footerTable = () => {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flex: 1,
						width: '100%',
					}}>
					<div style={{ width: 60 }}>Total: {totalCount || 0}</div>
					<div style={{ width: 480 }}></div>

					<KrwPrice
						style={{ width: 200 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['amount'] || 0)
									.toNumber(),
							0
						)}
					/>

					<KrwPrice
						style={{ width: 180 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['confirm_amount'] || 0)
									.toNumber(),
							0
						)}
					/>
					<div style={{ flex: 1 }}></div>
				</div>
			)
		}
	}
	if (footer == 4) {
		footerTable = () => {
			const groupedData = dataSource.reduce((acc, curr) => {
				const userId = curr['id']
				const existingEntry = acc.find(
					entry => entry['user_id'] === userId
				)

				if (existingEntry) {
					// 이미 그룹이 존재하는 경우
					if (curr['daydate'] > existingEntry['daydate']) {
						// 현재 데이터가 더 최근인 경우 값을 업데이트
						existingEntry['last_balance'] = curr['last_balance']
					}
				} else {
					// 그룹이 존재하지 않는 경우 새로운 그룹 생성
					acc.push({
						user_id: userId,
						last_balance: curr['last_balance'],
						daydate: curr['daydate'], // 최근값 비교를 위한 timestamp 추가
					})
				}

				return acc
			}, [])
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						alignItems: 'center',
						flex: 1,
						width: '100%',
					}}>
					<div
						style={{
							width: '100%',
							borderBottom: '1px solid #efefef',
							marginBottom: 10,
							paddingBottom: 10,
							fontSize: 12,
						}}>
						총갯수: {totalCount || 0}
					</div>

					<View
						style={{
							flexDirection: 'row',
							gap: 10,
							width: '100%',
							flex: 1,
						}}>
						<View
							style={{
								width: '50%',
								flex: 1,
							}}>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'column',
									paddingInline: 10,
									borderBottom: '1px solid #efefef',
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										textAlign: 'left',
										fontSize: 12,
									}}>
									일반 입금 합계
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr['total_deposit_amount']
												)
												.toNumber(),
										0
									)}
								/>
							</View>

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'column',
									paddingInline: 10,
									borderBottom: '1px solid #efefef',
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										textAlign: 'left',
										fontSize: 12,
									}}>
									별도 입금 합계
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr[
														'total_deposit_amount_extra'
													]
												)
												.toNumber(),
										0
									)}
								/>
							</View>

							{!COINHIDE ? (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'column',
										paddingInline: 10,
										borderBottom: '1px solid #efefef',
									}}>
									<Text
										style={{
											width: '100%',
											justifyContent: 'flex-start',
											fontSize: 12,
											textAlign: 'left',
										}}>
										{'코인>해선합계'}
									</Text>
									<KrwPrice
										w={'100%'}
										style={{ width: '100%' }}
										coinglobal={coinglobal}
										price={dataSource.reduce(
											(acc, curr) =>
												BigNumber(acc)
													.plus(
														curr[
															'total_deposit_amount_move'
														]
													)
													.toNumber(),
											0
										)}
									/>
								</View>
							) : (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'column',
										paddingInline: 10,
										borderBottom: '1px solid #efefef',
									}}>
									-
								</View>
							)}

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									borderBottom: '1px solid #efefef',
									borderTop: '1px solid #efefef',
									flexDirection: 'column',
									paddingInline: 10,
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										textAlign: 'left',
										fontSize: 12,
									}}>
									{'입금총합 합계'}
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr[
														'total_deposit_amount_move'
													]
												)
												.plus(
													curr[
														'total_deposit_amount_extra'
													]
												)
												.plus(
													curr['total_deposit_amount']
												)
												.toNumber(),
										0
									)}
								/>
							</View>
						</View>

						<View
							style={{
								width: '50%',
							}}>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'column',
									paddingInline: 10,
									borderBottom: '1px solid #efefef',
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										fontSize: 12,
										textAlign: 'left',
									}}>
									일반 출금 합계
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'column',
									paddingInline: 10,
									borderBottom: '1px solid #efefef',
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										fontSize: 12,
										textAlign: 'left',
									}}>
									별도 출금 합계
								</Text>

								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount_extra'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>
							{!COINHIDE ? (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'column',
										paddingInline: 10,
										borderBottom: '1px solid #efefef',
									}}>
									<Text
										style={{
											width: '100%',
											justifyContent: 'flex-start',
											textAlign: 'left',
											fontSize: 12,
										}}>
										{'해선>코인 합계'}
									</Text>

									<KrwPrice
										w={'100%'}
										style={{ width: '100%' }}
										coinglobal={coinglobal}
										price={
											-Math.abs(
												dataSource.reduce(
													(acc, curr) =>
														BigNumber(acc)
															.plus(
																curr[
																	'total_withdraw_amount_move'
																]
															)
															.toNumber(),
													0
												)
											)
										}
									/>
								</View>
							) : (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'column',
										paddingInline: 10,
										borderBottom: '1px solid #efefef',
									}}>
									-
								</View>
							)}

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									borderBottom: '1px solid #efefef',
									borderTop: '1px solid #efefef',
									flexDirection: 'column',
									paddingInline: 10,
								}}>
								<Text
									style={{
										width: '100%',
										fontSize: 12,
										justifyContent: 'flex-start',
										textAlign: 'left',
									}}>
									{'출금총합 합계'}
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount_move'
															]
														)
														.plus(
															curr[
																'total_withdraw_amount_extra'
															]
														)
														.plus(
															curr[
																'total_withdraw_amount'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>
						</View>
					</View>

					<View
						style={{
							width: '100%',
							padding: 10,
							border: '1px solid #efefef',
							backgroundColor: theme.COLORS.tradeBG,
							borderRadius: 5,
							marginTop: 10,
							gap: 5,
						}}>
						<View
							style={{
								whiteSpace: 'nowrap',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Text
								style={{
									width: '100%',
									fontSize: 12,
									justifyContent: 'flex-start',
									textAlign: 'left',
									color: theme.COLORS.textw,
								}}>
								총매매손익 합
							</Text>

							<KrwPrice
								coinglobal={coinglobal}
								w={'100%'}
								style={{ width: '100%' }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(curr['total_pnl_price'] || 0)
											.toNumber(),
									0
								)}
							/>
						</View>
						<View
							style={{
								whiteSpace: 'nowrap',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Text
								style={{
									width: '100%',
									fontSize: 12,
									justifyContent: 'flex-start',
									textAlign: 'left',
									color: theme.COLORS.textw,
								}}>
								총수수료 합계
							</Text>

							<KrwPrice
								coinglobal={coinglobal}
								w={'100%'}
								style={{ width: '100%' }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(
												-Math.abs(
													curr['total_fee_price']
												) || 0
											)
											.toNumber(),
									0
								)}
							/>
						</View>
						<View
							style={{
								whiteSpace: 'nowrap',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Text
								style={{
									width: '100%',
									fontSize: 12,
									justifyContent: 'flex-start',
									textAlign: 'left',
									color: theme.COLORS.textw,
								}}>
								실현손익 합계
							</Text>
							<KrwPrice
								coinglobal={coinglobal}
								w={'100%'}
								style={{ width: '100%' }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(
												curr['total_trade_price'] || 0
											)
											.toNumber(),
									0
								)}
							/>
						</View>
						<View
							style={{
								whiteSpace: 'nowrap',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Text
								style={{
									width: '100%',
									fontSize: 12,
									justifyContent: 'flex-start',
									textAlign: 'left',
									color: theme.COLORS.textw,
								}}>
								마지막잔액 합
							</Text>

							<KrwPrice
								coinglobal={coinglobal}
								w={'100%'}
								style={{ width: '100%' }}
								price={groupedData.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(curr['last_balance'] || 0)
											.toNumber(),
									0
								)}
							/>
						</View>
					</View>
				</div>
			)
		}
	}

	if (footerTable) {
		return footerTable()
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				flex: 1,
				width: '100%',
			}}>
			<div style={{ width: 60 }}>Total: {totalCount || 0}</div>
			<div style={{ width: 480 }}></div>
		</div>
	)
}

export default React.memo(TableFooter)

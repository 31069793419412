import React, { useEffect, useState } from 'react'

import { Space } from 'antd'
import CanvasTableAPI from 'app/components/TableAPI/CanvasTableAPI'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import ExceptBox from './ExceptBox'
import { PartnerRealtimePositionColumn } from './PartnerRealtimePositionColumn'
import SearchFilter from './SearchFilter'

const PartnerRealtimePosition = ({
	group,
	height = 500,
	width = 100,
	scale = 1,
}) => {

	const { data: coinglobal } = useCoinGlobal()

	const [exceptType1, setExceptType1] = useState(true)
	const [exceptType2, setExceptType2] = useState(true)
	const [exceptType3, setExceptType3] = useState(true)
	const [exceptType4, setExceptType4] = useState(true)
	const [exceptType5, setExceptType5] = useState(true)

	const [search, setSearch] = React.useState('')
	const [search2, setSearch2] = React.useState(null)

	const [exceptType, setExceptType] = useState('')

	const [api, setApi] = React.useState<any>(`/group/history/positions?ep_id=*
				${(exceptType && `&excepttype=${exceptType}`) || ''}
				${search2 ? `&searchWord=${search2}` : ''}
				${group ? `&group=${group}` : ''}
				&t=`)

	useEffect(() => {
		let exceptType = ''
		if (exceptType1) exceptType += `${exceptType ? ',' : ''}-2`
		if (exceptType2) exceptType += `${exceptType ? ',' : ''}-1`
		if (exceptType3) exceptType += `${exceptType ? ',' : ''}-9`
		if (exceptType4) exceptType += `${exceptType ? ',' : ''}6`
		if (exceptType5) exceptType += `${exceptType ? ',' : ''}7`
		setExceptType(exceptType)
	}, [exceptType1, exceptType2, exceptType3, exceptType4, exceptType5])

	useEffect(() => {
		const url = `/group/history/positions?ep_id=*
				${(exceptType && `&excepttype=${exceptType}`) || ''}
				${search2 ? `&searchWord=${search2}` : ''}
				${group ? `&group=${group}` : ''}
				&t=`
		setApi(url)
	}, [exceptType, search, search2, group])


	return (
		<CanvasTableAPI
			title={'활성종목'}
			className={'plist nopadding'}
			coinglobal={coinglobal}
			height={height}
			width={width}
			minusHeight={70}
			defaultMaxData={800}
			autorefresh={7000}
			scale={scale}
			loading={false}
			footer={9}
			extra={
				<Space
					style={{
						whiteSpace: 'nowrap',
						alignContent: 'center',
						justifyItems: 'center',
						justifyContent: 'center',
					}}>
					<ExceptBox
						exceptType1={exceptType1}
						setExceptType1={setExceptType1}
						exceptType2={exceptType2}
						setExceptType2={setExceptType2}
						exceptType3={exceptType3}
						setExceptType3={setExceptType3}
						exceptType4={exceptType4}
						setExceptType4={setExceptType4}
						exceptType5={exceptType5}
						setExceptType5={setExceptType5}
					/>
					<SearchFilter
						search={search}
						search2={search2}
						setSearch={setSearch}
						setSearch2={setSearch2}
					/>
				</Space>
			}
			columns={PartnerRealtimePositionColumn}
			usermodal={true}
			apikey={api}
		/>
	)
}

export default React.memo(PartnerRealtimePosition)

import React, { useContext, useState } from 'react'
import { Group } from 'react-konva'
import { Text } from 'styles/canvas'
import { ThemeContext } from 'theme/ThemeProvider'

const CanvasUserTag = ({
	userid,
	nickname = '',
	email = '',
	phone = '',
	uid = '',
	onClick,
	selected = false,
	style = {},
	level = undefined,
	p,
}: any) => {
	let showid = userid

	// useState로 hover 상태 관리
	const [hover, setHover] = useState(false)
	const {canvasColor} = useContext(ThemeContext)

	if (uid) {
		showid = uid
	} else if (email) {
		const tempemail = email.split('@')[0]
		showid = tempemail
	} else if (phone) {
		phone = phone.replace(/-/g, '')
		showid = phone
	}

	if (nickname && nickname != email) {
		showid = nickname
	}

	return (
		<Group
			onClick={onClick}
			onMouseEnter={() => {
				setHover(true)
			}}
			onMouseOut={() => {
				setHover(false)
			}}>
			<Text
				x={p.x}
				y={p.y}
				width={p.width}
				bgColor={hover ? '#ffa600' :canvasColor.nameBg}
				fontWeight={700}
				height={p.height}>
				{showid}
			</Text>
		</Group>
	)
}

export default React.memo(CanvasUserTag)

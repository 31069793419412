import { useTimezone } from 'app/hooks/timezone'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import React from 'react'
import { Text } from 'styles/canvas'

dayjs.extend(duration)

const CanvasTime = ({
	time,
	format = 'YYYY-MM-DD HH:mm:ss',
	style = {
		fontSize: 14,
		letterSpacing: -1,
	},
	fontSize = 12,
	relative = false,
	relativeAdd = false,
	offset = 0,
	p = { x: 0, y: 0, width: 100, height: 25 },
}: any) => {
	const printtime = useTimezone(time, format, offset)

	if (!time) return <></>
	let displayTime

	if (relative) {
		const now = dayjs()
		const diffMonths = now.diff(time, 'months')
		if (diffMonths >= 12) {
			const diffYears = Math.floor(diffMonths / 12)
			const remainingMonths = diffMonths % 12
			displayTime = `${diffYears}년 ${remainingMonths}개월 전`
		} else if (diffMonths > 1) {
			displayTime = `${diffMonths}개월 전`
		} else {
			const diff = dayjs.duration(dayjs().diff(dayjs(time)))

			if (diff.asDays() > 1) {
				displayTime = `${Math.floor(diff.asDays())}일 전`
			} else if (diff.asHours() > 0) {
				displayTime = `${Math.floor(diff.asHours())}시간 전`
			} else if (diff.asMinutes() > 0) {
				displayTime = `${Math.floor(diff.asMinutes())}분 전`
			} else {
				displayTime = `${Math.floor(diff.asSeconds())}초 전`
			}
		}
	}

	return (
		<>
			{relativeAdd == true ? (
				<>
					<Text
						x={p.x}
						y={p.y}
						width={p.width}
						height={p.height}
						align="center">
						{printtime}
					</Text>
					{relative && (
						<Text
							x={p.x}
							y={p.y}
							width={p.width}
							height={p.height}
							align="center">
							{displayTime}
						</Text>
					)}
				</>
			) : (
				<>
					<Text
						x={p.x}
						y={p.y}
						width={p.width}
						height={p.height}
						fontSize={fontSize}
						letterSpacing={-1}
						align="center">
						{relative ? displayTime : printtime}
					</Text>
				</>
			)}
		</>
	)
}

export default React.memo(CanvasTime)

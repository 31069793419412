import { Select } from 'antd'
import React, { useState } from 'react'

import CanvasTableAPI from 'app/components/TableAPI/CanvasTableAPI'
import dayjs from 'dayjs'
import { useWindowDimensions } from '../../hooks'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import DateFilter from '../DateFilter'
import { PartnerTradelistColumn } from './PartnerTradeListColumn'

const PartnerTradeList = ({ mobile = false, userid = null }) => {
	const { width } = useWindowDimensions()

	let now = dayjs().format('YYYY-MM-DD')
	const today = dayjs().format('YYYY-MM-DD')
	const prev = dayjs(today).subtract(1, 'day').format('YYYY-MM-DD')

	//새벽 12시~오전 7시 사이에 조회할 경우 전날 데이터 조회
	if (dayjs().hour() < 7) {
		now = prev
	} else {
		now = today
	}
	const { data: coinglobal } = useCoinGlobal()
	const [startDate, setStartDate] = useState<any>(now)
	const [endDate, setEndDate] = useState<any>(now)
	const [orderType, setOrderType] = useState<any>('')
	const [ep, setEp] = useState<any>('*')

	const { EPs } = useExchangeStore()

	return (
		<CanvasTableAPI
			coinglobal={coinglobal}
			className={'plist tradelist'}
			excel={false}
			fullheight={true}
			minusHeight={150}
			topSum={true}
			title={
				<>
					<DateFilter
						setStartDate={setStartDate}
						setEndDate={setEndDate}
						startDate={startDate}
						endDate={endDate}
						mobile={mobile}
					/>

					<>
						<Select
							value={ep}
							onChange={e => setEp(e)}
							style={{ minWidth: 200 }}>
							<Select.Option value="*" key={'all'}>
								전체종목
							</Select.Option>
							{EPs?.map((ep, i) => (
								<Select.Option
									value={ep?.id}
									key={'option' + ep?.id}>
									{ep?.pairs} ({ep?.groupname})
								</Select.Option>
							))}
						</Select>

						<Select
							value={orderType}
							onChange={e => setOrderType(e)}
							style={{ minWidth: 150 }}>
							<Select.Option key={'s1'} value="">
								최근순
							</Select.Option>
							<Select.Option key={'s2'} value="2">
								오래된순
							</Select.Option>
							<Select.Option key={'s6'} value="6">
								체결일자 최근순
							</Select.Option>
							<Select.Option key={'s5'} value="5">
								체결일자 오래된순
							</Select.Option>
							<Select.Option key={'s3'} value="4">
								매매수익순
							</Select.Option>
							<Select.Option key={'s4'} value="3">
								매매손해순
							</Select.Option>
						</Select>
					</>
				</>
			}
			defaultMaxData={500}
			stylewidth={'100%'}
			height={userid ? 'default' : 500}
			columns={PartnerTradelistColumn}
			apikey={`/${userid ? 'group' : 'trade'}/orderlist?type=VER1${
				startDate ? `&sdate=${startDate}` : ''
			}${
				endDate ? `&edate=${endDate}` : ''
			}&ordertype=${orderType}&ep_id=${ep}${
				userid ? '&user_id=' + userid : ''
			}&t=`}
		/>
	)
}

export default React.memo(PartnerTradeList)

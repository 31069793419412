import React from 'react'

import BigNumber from 'bignumber.js'
import CanvasLevel from './Columns/CanvasLevel'
import CanvasOnline from './Columns/CanvasOnline'
import CanvasPrice from './Columns/CanvasPrice'
import CanvasUserTag from './Columns/CanvasUserTag'

export const PartnerRealtimeCustomerColumn = (
	mutate,
	selectedData,
	token,
	fold,
	shareMutate,
	getPrice,
	getRate,
	krwrate,
	EPs
) => [
	{
		title: '접속',
		dataIndex: 'client',
		key: 'client',
		align: 'center',
		width: 40,
		sorter: (a, b) => a?.client - b?.client,
		render: (text, data, p) => {
			return <CanvasOnline data={data} p={p} />
		},
	},
	{
		title: '그룹',
		dataIndex: 'referral_code',
		key: 'referral_code',
		sorter: (a, b) => a?.referral_code?.localeCompare(b.referral_code),
		width: 60,
	},
	{
		title: '등급',
		dataIndex: 'level',
		key: 'level',
		align: 'center',
		width: 40,
		sorter: (a, b) => a?.level - b?.level,
		render: (text, data, p) => {
			return <CanvasLevel level={data?.level} p={p} />
		},
	},
	{
		title: '회원',
		dataIndex: 'fullname',
		key: 'fullname',
		align: 'center',
		sorter: (a, b) => a?.fullname?.localeCompare(b.fullname),
		render: (text, data, p) => {
			return (
				<CanvasUserTag
					p={p}
					userid={data?.id}
					email={data?.email}
					phone={data?.phone}
					nickname={data?.fullname || data?.nickname}
					onClick={() => {
						window.open(
							`/partner/user/${data?.id}`,
							'_blank',
							'width=1750, height=800, left=0, top=0, location=no, toolbar=no, menubar=no, status=no, scrollbars=yes, resizable=yes'
						)
					}}
				/>
			)
		},
	},
	{
		title: '아이디',
		dataIndex: 'loginid',
		key: 'loginid',
		align: 'center',
		sorter: (a, b) => a?.loginid?.localeCompare(b.loginid),
	},
	{
		title: '평가손익',
		dataIndex: 'realtimebalance',
		key: 'realtimebalance',
		align: 'center',
		width: 70,
		sum: true,
		sorter: (a, b) => a?.realtimebalance - b?.realtimebalance,
		render: (text, data, props) => {
			return (
				<CanvasPrice
					price={data?.realtimebalance || 0}
					color={true}
					dp={0}
					p={props}
				/>
			)
		},
	},

	{
		title: '총매매손익',
		dataIndex: 'total_pnl_price',
		key: 'total_pnl_price',
		align: 'center',
		width: 70,
		rowScope: 'row',
		sum: true,
		sorter: (a, b) => a?.total_pnl_price - b?.total_pnl_price,
		render: (text, data, props) => (
			<CanvasPrice
				price={text}
				ep_id={data?.exchange_pairs_id}
				color={true}
				dp={0}
				p={props}
			/>
		),
	},
	{
		title: '수수료',
		dataIndex: 'total_fee_price',
		key: 'total_fee_price',
		sorter: (a, b) => a?.total_fee_price - b?.total_fee_price,
		align: 'center',
		width: 70,
		sum: true,
		render: (text, data, props) => (
			<CanvasPrice
				price={-Math.abs(text)}
				color={true}
				dp={0}
				p={props}
			/>
		),
	},
	{
		title: '실현손익',
		dataIndex: 'total_trade_price',
		key: 'total_trade_price',
		align: 'center',
		width: 70,
		sum: true,
		sorter: (a, b) => a?.total_trade_price - b?.total_trade_price,
		render: (text, data, props) => (
			<CanvasPrice price={text || 0} color={true} dp={0} p={props} />
		),
	},
	{
		title: '실시간손익',
		dataIndex: 'realtime',
		key: 'realtime',
		align: 'center',
		width: 70,
		sum: true,
		sorter: (a, b) => a?.realtime - b?.realtime,
		render: (text, data, props) => {

			return (
				<CanvasPrice
					price={data?.realtime || 0}
					color={true}
					dp={0}
					p={props}
				/>
			)
		},
	},
	{
		title: '평가금액',
		dataIndex: 'allbalance',
		key: 'allbalance',
		align: 'center',
		sum: true,
		sorter: (a, b) => a?.allbalance - b?.allbalance,
		render: (text, data, props) => {
			return (
				<CanvasPrice
					price={data?.allbalance || data?.last_balance || 0 || 0}
					color={false}
					dp={0}
					p={props}
				/>
			)
		},
	},
	{
		title: '실잔액',
		dataIndex: 'last_balance',
		key: 'last_balance',
		align: 'center',
		sum: true,
		sorter: (a, b) => a?.last_balance - b?.last_balance,
		render: (text, data, props) => {
			return (
				<CanvasPrice price={text || 0} color={false} dp={0} p={props} />
			)
		},
	},
	{
		title: '로스컷',
		dataIndex: 'losscut',
		key: 'losscut',
		align: 'center',
		sum: true,
		sorter: (a, b) => a?.losscut - b?.losscut,
		render: (text, data, props) => {
			return (
				<CanvasPrice price={text || 0} color={false} dp={0} p={props} />
			)
		},
	},
]

import React from 'react'

import { Text } from 'styles/canvas'
import CanvasContracts from './Columns/CanvasContracts'
import CanvasPNL from './Columns/CanvasPNL'
import CanvasPrice from './Columns/CanvasPrice'
import CanvasRealtimePrice from './Columns/CanvasRealtimePrice'
import CanvasUserTag from './Columns/CanvasUserTag'
import { typeFromLevelPartner } from 'utils/ciutils'
import CanvasLevel from './Columns/CanvasLevel'
import CanvasLongShort from './Columns/CanvasLongShort'
/*
() => [
				{
					title: '번호',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
					width: 80,
					render: (text, record) => <Text>{text}</Text>,
				},
				{
					title: '회원',
					dataIndex: 'user_id',
					key: 'user_id',
					align: 'center',
					sorter: (a: any, b: any) => b.user_id - b.user_id,
					render: (text, record) => (
						<UserTag
							userid={record?.user_id}
							nickname={record?.fullname || record?.nickname}
							setSelectedData={() => {
								setModal(true)
								setSelectedData(record?.user_id)
							}}
						/>
					),
				},
				{
					title: '종목',
					dataIndex: 'pairs',
					key: 'pairs',

					render: (text, record) => (
						<Contracts2
							data={record}
							created_at={record?.created_at}
						/>
					),
				},
				{
					title: '수량',
					dataIndex: 'amount',
					align: 'center',
					sorter: (a, b) => a.amount - b.amount,
					render: (text, data) => <Text>{text}</Text>,
				},
				{
					title: '주문가',
					align: 'center',
					dataIndex: 'order_price',
					key: 'order_price',
					sorter: (a, b) => a.order_price - b.order_price,
					render: (text, record) => {
						const EP = EPs.find(
							item => item.id === record?.exchange_pairs_id
						)

						return (
							<>
								{record?.limit_market == 'L' ? (
									<KrwPrice
										price={text}
										coinglobal={coinglobal}
										color={false}
										theme={false}
										ep_id={record?.exchange_pairs_id}
										dp={EP?.dp}
										krw={false}
									/>
								) : (
									<>-</>
								)}
							</>
						)
					},
				},
				{
					title: '현재가',
					align: 'center',
					dataIndex: 'mark_price',
					key: 'mark_price',
					render: (text, record) => (
						<MarkPrice data={record} partner={true} />
					),
				},
				{
					title: '매매손익',
					align: 'center',
					dataIndex: 'pnl_price',
					key: 'pnl_price',
					sorter: (a, b) => a.pnl_price - b.pnl_price,
					render: (text, record) => (
						<>
							<KrwPrice
								price={text}
								ep_id={record?.exchange_pairs_id}
								coinglobal={coinglobal}
								dp={0}
								krw={true}
							/>
						</>
					),
				},
				{
					title: '수수료',
					align: 'center',
					dataIndex: 'fee_price',
					key: 'fee_price',
					sorter: (a, b) => a.fee_price - b.fee_price,
					render: (text, record) => (
						<KrwPrice
							price={-text}
							coinglobal={coinglobal}
							ep_id={record?.exchange_pairs_id}
							dp={0}
							krw={true}
						/>
					),
				},
			]
*/
export const PartnerRealtimeWaitColumn = (mutate = () => {}, showModal) => [
	{
		title: '번호',
		dataIndex: 'id',
		width: 60,
		align: 'center',
		sorter: (a, b) => a.id - b.id,
		render: (text, data, props) => (
			<Text
				x={props.x}
				y={props.y}
				width={props.width}
				height={props.height}>
				{text}
			</Text>
		),
	},
	{
		title: '그룹',
		dataIndex: 'referral_code',
		key: 'referral_code',
		sorter: (a, b) => a?.referral_code?.localeCompare(b.referral_code),
		width: 70,
	},
	{
		title: '등급',
		dataIndex: 'level',
		key: 'level',
		align: 'center',
		width: 30,
		sorter: (a, b) => a?.level - b?.level,
		render: (text, data, p) => {
			return <CanvasLevel level={text} p={p} />
		},
	},
	{
		title: '회원',
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		sorter: (a, b) => a?.fullname?.localeCompare(b.fullname),
		render: (text, data, p) => {
			return (
				<CanvasUserTag
					p={p}
					userid={data?.user_id}
					email={data?.email}
					phone={data?.phone}
					nickname={data?.fullname || data?.nickname}
					onClick={() => {
						window.open(
							`/partner/user/${data?.user_id}`,
							'_blank',
							'width=1750, height=800, left=0, top=0, location=no, toolbar=no, menubar=no, status=no, scrollbars=yes, resizable=yes'
						)
					}}
				/>
			)
		},
	},
	{
		title: '아이디',
		dataIndex: 'loginid',
		key: 'loginid',
		align: 'center',
		sorter: (a, b) => a?.loginid?.localeCompare(b.loginid),

		// render: (text, data) => {
		// 	return <Text>{text}</Text>
		// },
	},
	{
		title: '종목',
		align: 'center',
		width: 130,
		dataIndex: 'contracts',
		sorter: (a, b) => a.exchange_pairs_id - b.exchange_pairs_id,
		render: (text, data, p = { x: 0, y: 0, width: 100, height: 25 }) => (
			<CanvasContracts data={data} p={p} />
		),
	},
	{
		title: '구분',
		align: 'center',
		width: 40,
		dataIndex: 'long_short',
		sorter: (a, b) => a.long_short.localeCompare(b.long_short),
		render: (text, data, p = { x: 0, y: 0, width: 100, height: 25 }) => (
			<CanvasLongShort long_short={text} p={p} />
		),
	},
	{
		title: '수량',
		dataIndex: 'amount',
		align: 'center',
		sum: true,
		width: 30,
		sorter: (a, b) => a.amount - b.amount,
		render: (text, data, props) => (
			<CanvasPrice
				price={data?.long_short == 'L' ? text : -text}
				dp={0}
				color={true}
				p={props}
			/>
		),
	},
	{
		title: '주문가',
		align: 'center',
		dataIndex: 'order_price',
		sum: true,
		avg: true,
		sorter: (a, b) => a.order_price - b.order_price,
		render: (text, data, props) => (
			<CanvasPrice
				price={text}
				ep_id={data?.exchange_pairs_id}
				p={props}
			/>
		),
	},
	{
		title: '현재가',
		align: 'center',
		dataIndex: 'price',
		width: 50,
		render: (text, data, props) => (
			<CanvasRealtimePrice data={data} p={props} />
		),
	},
	
]

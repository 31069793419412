import useSWR from 'swr'
import API from '../../../utils/api'
import { INDEXPIRCE } from '../../constants/TRADEAPIKEYS'
import { useToken } from '../auth/token'
let swrData: any = []
let getAPI = true

let lastcall: any = null

export function useIndexPrice(ep: number) {
	const { data: token } = useToken()
	let KEY: any = INDEXPIRCE + '?ep_id=' + 0

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (!getAPI) return swrData || []
			const response = await API.get(KEY, token + '', {}, false)
			swrData = response?.result

			setTimeout(() => {
				getAPI = false
			}, 100)

			return swrData || []
		},
		{
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
		}
	)

	const list =
		(data &&
			data?.length >= 0 &&
			data?.find((item: any) => item?.ep_id == ep)?.result) ||
		[]

	return {
		data: list,
		reset: (where) => {
			console.log('reset where', where)

			const now = new Date().getTime()
			if (lastcall != null && now - lastcall < 10000) {
				return
			}
			lastcall = now
			getAPI = true
			mutate()
		},
		mutate: (value?: any, epid?: number) => {
			try {
				return mutate((prev: any) => {
					if (prev?.find((item: any) => item?.ep_id == epid)) {
						const temp = prev?.map((item: any) => {
							if (item?.ep_id == epid) {
								return {
									ep_id: epid,
									result: { ...value },
								}
							} else {
								return item
							}
						})
						swrData = temp
						return temp
					} else {
						swrData = [
							...prev,
							{
								ep_id: epid,
								result: { ...value },
							},
						]
						return swrData
					}
				})
			} catch (e) {
				return mutate()
			}
		},
		getPrice: (epid?: number) => {
			try {
				return (
					data?.find((item: any) => item?.ep_id == (epid || ep))
						?.result?.[0]?.price || 0
				)
			} catch (e) {
				return 0
			}
		},
	}
}

import React from 'react'

import CanvasUserTag from './Columns/CanvasUserTag'
import { Rect } from 'react-konva'

export const PartnerCustomerColumnMini = (
	mutate,
	selectedData,
	token,
	fold,
	shareMutate,
	getPrice,
	getRate,
	krwrate,
	EPs,
	coinglobal,
	selectedid
) => [
	{
		title: '그룹',
		dataIndex: 'groupname',
		key: 'groupname',
		width: 70,
		sorter: (a, b) =>
			a.groupname < b.groupname ? -1 : a.groupname == b.groupname ? 0 : 1,
	},

	{
		title: '실명',
		dataIndex: 'fullname',
		key: 'fullname',
		align: 'center',
		sorter: (a, b) => a?.fullname?.localeCompare(b.fullname),
		render: (text, data, p) => {
			return (
				<>
					{data?.id == selectedid && (
						<Rect
							x={p.x}
							y={p.y}
							width={p.width}
							height={p.height}
							fill="rgb(255, 229, 157)"
						/>
					)}
					<CanvasUserTag
						p={p}
						userid={data?.id}
						email={data?.email}
						phone={data?.phone}
						nickname={data?.fullname || data?.nickname}
						onClick={() => {}}
					/>
				</>
			)
		},
	},
	{
		title: '아이디',
		dataIndex: 'loginid',
		key: 'loginid',
		align: 'center',
		sorter: (a, b) => a?.loginid?.localeCompare(b.loginid),
	},
]

import React, { useContext } from 'react'

import { Group, Text as KonvaText, Layer, Rect } from 'react-konva'
import { ThemeContext } from 'theme/ThemeProvider'

export const Text = ({
	children,
	x,
	y,
	width,
	height,
	fontSize = 13,
	fontFamily = 'Roboto',
	fill = '',
	wrap = 'none',
	fontWeight = 400,
	align = 'center',
	letterSpacing = -0.5,
	bgColor = '',
	padding = 2,
}) => {
	const { canvasColor } = useContext(ThemeContext)
	if (fill == '') {
		fill = canvasColor.text
	}

	let text = children || ''

	//숫자인 경우 콤마 추가 소수점고려
	if (typeof text === 'number') {
		text = text.toLocaleString()
	}

	return (
		<>
			{bgColor && (
				<Rect
					x={x}
					y={y}
					width={width}
					height={height}
					fill={bgColor}
					stroke={canvasColor.stroke}
					strokeWidth={1}
				/>
			)}
			<Group  width={width} height={height}>
				<KonvaText
					x={x}
					y={y}
					width={width}
					height={height}
					fill={fill}
					verticalAlign="middle"
					align={align}
					padding={padding}
					text={text}
					wrap={wrap}
					fontSize={fontSize}
					letterSpacing={letterSpacing}
					fontStyle={fontWeight === 700 ? '500' : 'normal'}
					fontFamily={fontFamily}
				/>
			</Group>
		</>
	)
}

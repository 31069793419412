import { Button, Card, Descriptions, Space } from 'antd'
import { useToken, useWindowDimensions } from 'app/hooks'
import GroupFilter from 'app/pages/ReferralPage/GroupFilter'
import { PartnerCustomerColumnMini } from 'app/pages/ReferralPage/PartnerCustomerColumnMini'
import SearchFilter from 'app/pages/ReferralPage/SearchFilter'
import React, { useCallback, useEffect, useState } from 'react'
import { Text, View } from 'styles/reactnative'
import API from 'utils/api'
import { API_COINURL, PARTNERURL } from '../constants/APIKEYS'
import { useCoinGlobal } from '../hooks/stateStore/coinGlobal'
import { useProfile } from '../hooks/user/profilestore'
import CanvasTableAPI from './TableAPI/CanvasTableAPI'
import UserInfotabs from './UserInfotabs'

const UserContent = ({
	showOpen,
	selectedData,
	setSelectedData,
	defaultfold = false,
}) => {
	const { width } = useWindowDimensions()
	const [data, setData] = useState<any>({})
	const [level, setLevel] = useState('1')

	const fold = true
	const { data: coinglobal, mutate: mutateCG } = useCoinGlobal()

	const { mutate } = useProfile(selectedData)
	const { data: token } = useToken()
	const [group, setGroup] = useState('*')

	useEffect(() => {
		getUser()
	}, [coinglobal])

	const getUser = useCallback(() => {
		if (!selectedData) return
		API.get(
			`/group/users/${
				isNaN(selectedData) ? 1 : selectedData
			}?user_id=${selectedData}`,
			token + '',
			{},
			false,
			coinglobal == 'C' ? API_COINURL : PARTNERURL
		).then((res: any) => {
			if (res?.result?.success) {
				mutate(selectedData, res?.result?.result)
				setData(res?.result?.result)
			}
		})
	}, [selectedData, token, coinglobal])

	const [search, setSearch] = React.useState(selectedData || '')
	const [search2, setSearch2] = React.useState(null)

	const timerref = React.useRef<any>(null)

	useEffect(() => {
		if (selectedData) {
			getUser()
		}
	}, [token, selectedData, showOpen])

	return (
		<View
			style={{
				flexDirection: width < 600 ? 'column' : 'row',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
				width: '100%',
				height: '100%',
			}}>
			<Card
				size="small"
				title={
					<Descriptions
						size="small"
						style={{ padding: 0, marginTop: 10 }}
						column={2}
						labelStyle={{ marginLeft: 10 }}
						contentStyle={{ fontWeight: 'normal' }}>
						<Descriptions.Item>
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									width: '100%',
									background: '#404040',
									color: 'white',
									padding: 5,
								}}>
								<Text>{data?.fullname || data?.nickname}</Text>
								<Text style={{ marginRight: 10 }}>
									{data?.loginid}
								</Text>
							</View>
						</Descriptions.Item>
					</Descriptions>
				}
				style={{
					width: '22%',
					height: '100%',
					margin: 0,
					padding: 0,
					minHeight: 600,
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<CanvasTableAPI
					defaultMaxData={5000}
					excel={false}
					fullheight={true}
					minusHeight={200}
					extra={
						<View
							style={{
								flexDirection: 'column',
								justifyContent: 'space-between',
								width: '100%',
								margin: 0,
								padding: 0,
							}}>
							<GroupFilter
								group={group}
								setGroup={setGroup}
								width={'100%'}
							/>
							<Space.Compact
								size="small"
								style={{ width: '100%', height: 23 }}>
								<Button
									size="small"
									danger={level == '*' ? true : false}
									onClick={() => {
										setLevel('*')
									}}>
									전체
								</Button>
								<Button
									danger={level == '1' ? true : false}
									size="small"
									onClick={() => {
										setLevel('1')
									}}>
									활동
								</Button>
								<Button
									danger={level == '6' ? true : false}
									size="small"
									onClick={() => {
										setLevel('6')
									}}>
									모의
								</Button>
								<SearchFilter
									search={search}
									setSearch={setSearch}
									search2={search2}
									setSearch2={setSearch2}
								/>
							</Space.Compact>
						</View>
					}
					stylewidth={'100%'}
					pagination={false}
					height={500}
					className="plist"
					selectedid={selectedData}
					rowAction={data => {
						setSelectedData(data?.id)
						mutate(data?.id, data)
						setData(data)
						try {
							window.history.pushState(
								{},
								'',
								`/partner/user/${data?.id}`
							)
						} catch (e) {}
					}}
					columns={PartnerCustomerColumnMini}
					apikey={`/group/users?group=${group}&level=${level}&${
						search2 ? 'type=user_id&searchWord=' + search2 : 't='
					}`}
				/>
			</Card>

			<UserInfotabs data={data} fold={fold} />
		</View>
	)
}

export default React.memo(UserContent)

import { useExchangeStore } from 'app/hooks/trade/exchangeStore'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import BigNumber from 'bignumber.js'
import React from 'react'
import { Rect } from 'react-konva'
import { Text } from 'styles/canvas'

const CanvasRealtimePrice = ({
	data,
	p = { x: 0, y: 0, width: 100, height: 25 },
}) => {
	const {EPs} = useExchangeStore()
	let { getPrice } = useIndexPrice(data?.exchange_pairs_id)
	const EP = EPs?.find((ep) => ep.id === data?.exchange_pairs_id)


	return (
		<>
			<Rect
				x={p.x + 1}
				y={p.y + 1}
				width={p.width - 1}
				height={p.height - 1}
				fill={'#e7e7e7'}
			/>
			<Text
				x={p.x}
				y={p.y}
				width={p.width}
				align="right"
				fill={'#525252'}
				fontWeight={700}
				fontSize={10}
				height={p.height}>
				{BigNumber(getPrice(data?.exchange_pairs_id)).toFormat(
					EP?.dp || 0
				)}
			</Text>
		</>
	)
}

export default React.memo(CanvasRealtimePrice)

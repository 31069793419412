import { Space, Switch } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const ExceptBox = ({
	exceptType1,
	setExceptType1,
	exceptType2,
	setExceptType2,
	exceptType3,
	setExceptType3,
	exceptType4,
	setExceptType4,
	exceptType5,
	setExceptType5,
}) => {
	const { theme } = React.useContext(ThemeContext)
	return (
		<ExceptWrapper
			style={{
				backgroundColor: theme.COLORS.descBox,
				flexDirection: 'row',
				padding: '2px 2px',
				borderRadius: 5,
				gap: 1,
			}}>
			<Text
				style={{
					fontWeight: 'bold',
					fontSize: 11,
					color: theme.COLORS.textw,
					backgroundColor: theme.COLORS.descBox,
					borderRadius: 5,
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					padding: '4px 2px',
				}}>
				제외필터
			</Text>
			<View
				style={{
					flexDirection: 'row',
					color: theme.COLORS.textw,
					backgroundColor: theme.COLORS.background,
					borderRadius: 5,
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					padding: '2px 2px',
				}}>
				<Space.Compact
					size="small"
					style={{
						alignItems: 'center',
						whiteSpace: 'nowrap',
						fontSize: 10,
					}}>
					거절
					<Switch
						onChange={e => setExceptType1(e)}
						defaultChecked={exceptType1}
					/>
				</Space.Compact>
				<Space.Compact
					size="small"
					style={{
						alignItems: 'center',
						whiteSpace: 'nowrap',
						fontSize: 10,
					}}>
					대기
					<Switch
						onChange={e => setExceptType2(e)}
						defaultChecked={exceptType2}
					/>
				</Space.Compact>
				<Space.Compact
					size="small"
					style={{
						alignItems: 'center',
						whiteSpace: 'nowrap',
						fontSize: 10,
					}}>
					블랙
					<Switch
						onChange={e => setExceptType3(e)}
						defaultChecked={exceptType3}
					/>
				</Space.Compact>
				<Space.Compact
					size="small"
					style={{
						alignItems: 'center',
						whiteSpace: 'nowrap',
						fontSize: 10,
					}}>
					모의
					<Switch
						onChange={e => setExceptType4(e)}
						defaultChecked={exceptType4}
					/>
				</Space.Compact>

				<Space.Compact
					size="small"
					style={{
						alignItems: 'center',
						whiteSpace: 'nowrap',
						fontSize: 10,
					}}>
					페이크
					<Switch
						onChange={e => setExceptType5(e)}
						defaultChecked={exceptType5}
					/>
				</Space.Compact>
			</View>
		</ExceptWrapper>
	)
}

export default React.memo(ExceptBox)

const ExceptWrapper = styled(View)`
	& .ant-switch {
		scale: 0.85;
	}
`

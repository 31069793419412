import * as React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { GlobalStyle } from 'styles/global-styles'
import { View } from '../styles/reactnative'
import Logout from './components/Logout'
import { NotFoundPage } from './components/NotFoundPage/Loadable'
import { useToken } from './hooks'
import { PrivacyPolicyPage } from './pages/ASecondaryPages/PrivacyPolicyPage'
import { LoginPage } from './pages/AuthenticationPages/LoginPage/Loadable'
import { SignUpPage } from './pages/AuthenticationPages/SignUpPage/Loadable'
import { HomePage } from './pages/HomePage/Loadable'
import { NotificationPage } from './pages/NotificationPage/Loadable'
import { OrderBookView } from './pages/OrderBook'
import { OrdersPage } from './pages/OrdersPage/Loadable'
import { PositionView } from './pages/PostionView'
import { ProfileSettingPage } from './pages/ProfileSetting/Loadable'
import { ReferralPage } from './pages/ReferralPage/Loadable'
import { PartnerNewwindow } from './pages/ReferralPage/NewWindow/Customer'
import { PartnerUser } from './pages/ReferralPage/NewWindow/Users'
import { PartnerCash } from './pages/ReferralPage/PartnerCash'
import { PartnerRealtime } from './pages/ReferralPage/PartnerRealtime'
import { TradePage } from './pages/TradePage/Loadable'
import { ZMobileTradingView } from './pages/ZMobileTradingView/Loadable'

export function Routes() {
	// const { t, i18n } = useTranslation()
	// const realDevice = /iphone|ipad|ipod|android|mobile/i.test(
	// 	navigator.userAgent?.toLowerCase()
	// )
	// const { isMobile } = React.useContext(ThemeContext)
	// const { width } = useWindowDimensions()

	const { data: token } = useToken()
	var useragt = navigator.userAgent.toLowerCase()
	if (useragt.match(/kakaotalk/i)) {
		return (
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					fontSize: 20,
				}}>
				카카오톡 외부브라우저에서 접속해주세요.
			</View>
		)
	}
	return (
		<BrowserRouter>
			<Switch>
				<Route
					exact
					path="/trade/chart"
					component={ZMobileTradingView}
				/>
				<Route
					exact
					path="/trade/chart/*"
					component={ZMobileTradingView}
				/>
				<Route
					exact
					path="/settings"
					component={token ? ProfileSettingPage : LoginPage}
				/>
				<Route
					exact
					path="/settings/*"
					component={token ? ProfileSettingPage : LoginPage}
				/>
				<Route
					exact
					path="/partner"
					component={token ? ReferralPage : LoginPage}
				/>
				<Route
					exact
					path="/"
					component={token ? HomePage : LoginPage}
				/>
				{/* Authentication Page */}
				<Route
					exact
					path="/login/"
					component={token ? HomePage : LoginPage}
				/>

				<Route exact path="/logout" component={Logout} />

				<Route exact path="/trade/positions" component={PositionView} />
				<Route
					exact
					path="/trade/positions/*"
					component={PositionView}
				/>
				<Route
					exact
					path="/trade/orderbook"
					component={OrderBookView}
				/>
				<Route
					exact
					path="/trade/orderbook/*"
					component={OrderBookView}
				/>
				<Route
					exact
					path="/signup/"
					component={token ? HomePage : SignUpPage}
				/>
				{/* Authentication Page */}

				<Route
					exact
					path="/notifications/"
					component={NotificationPage}
				/>

				<Route
					exact
					path="/partner/customer"
					component={PartnerNewwindow}
				/>

				<Route exact path="/partner/cash" component={PartnerCash} />
				<Route
					exact
					path="/partner/realtime"
					component={PartnerRealtime}
				/>

				<Route exact path="/partner/user/*" component={PartnerUser} />

				{/* Trading View Page */}
				<Route
					exact
					path="/trade/"
					component={!token ? LoginPage : TradePage}
				/>
				<Route
					exact
					path="/trade/*"
					component={!token ? LoginPage : TradePage}
				/>
				{/* Trading View Page */}

				{/* Markets  Page */}
				<Route
					exact
					path="/referral/"
					component={!token ? LoginPage : ReferralPage}
				/>

				<Route
					exact
					path="/assets"
					component={!token ? LoginPage : HomePage}
				/>

				{/* Orders Page */}
				<Route
					exact
					path="/orders"
					component={!token ? LoginPage : OrdersPage}
				/>

				<Route
					exact
					path="/terms-service/"
					component={PrivacyPolicyPage}
				/>
				<Route path="*" component={NotFoundPage} />
			</Switch>

			<GlobalStyle />
		</BrowserRouter>
	)
}

import {
	IconZoomInArea,
	IconZoomOutArea,
	IconZoomReset,
} from '@tabler/icons-react'
import { Button, Space } from 'antd'
import React from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const RealTimeTopNav = ({
	onCustomer,
	onPosition,
	onWait,
	setOnCustomer,
	setOnPosition,
	setOnWait,
	scale,
	setScale,
}) => {
	const { theme } = React.useContext(ThemeContext)

	return (
		<View
			style={{
				backgroundColor: theme.COLORS.descBox,
				flexDirection: 'row',
				padding: '2px 2px',
				borderRadius: 5,
				gap: 2,
			}}>
			<Text
				style={{
					fontWeight: 'bold',
					fontSize: 11,
					color: theme.COLORS.textw,
					backgroundColor: theme.COLORS.descBox,
					borderRadius: 5,
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					padding: '4px 2px',
				}}>
				ON/OFF
			</Text>
			<View
				style={{
					flexDirection: 'row',
					color: theme.COLORS.textw,
					backgroundColor: theme.COLORS.background,
					borderRadius: 5,
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					padding: '2px 2px',
				}}>
				<Space.Compact size="small">
					<Button
						type={onCustomer ? 'primary' : 'default'}
						onClick={() => {
							setOnCustomer(!onCustomer)
							localStorage.setItem(
								'partner-realtime-onoff',
								JSON.stringify({
									customer: !onCustomer,
									position: onPosition,
									wait: onWait,
								})
							)
						}}>
						고객관리
					</Button>
					<Button
						type={onPosition ? 'primary' : 'default'}
						onClick={() => {
							setOnPosition(!onPosition)
							localStorage.setItem(
								'partner-realtime-onoff',
								JSON.stringify({
									customer: onCustomer,
									position: !onPosition,
									wait: onWait,
								})
							)
						}}>
						활성종목
					</Button>
					<Button
						type={onWait ? 'primary' : 'default'}
						onClick={() => {
							setOnWait(!onWait)
							localStorage.setItem(
								'partner-realtime-onoff',
								JSON.stringify({
									customer: onCustomer,
									position: onPosition,
									wait: !onWait,
								})
							)
						}}>
						미체결
					</Button>
					{/* <Button
						onClick={() => {
							window.location.href = '#realtime'
						}}>
						실시간장현황
					</Button> */}
				</Space.Compact>
			</View>

			<Space.Compact size="small">
				<Text style={{ color: theme.COLORS.textw }}>
					{scale.toFixed(1) + 'x'}
				</Text>

				<Button
					size="small"
					onClick={() => {
						localStorage.setItem(
							'partner-realtime-onoff',
							JSON.stringify({
								customer: onCustomer,
								position: onPosition,
								wait: onWait,
								scale: scale + 0.1,
							})
						)
						setScale(prev => prev + 0.1)
					}}>
					<IconZoomInArea size={20} />
				</Button>
				<Button
					size="small"
					onClick={() => {
						localStorage.setItem(
							'partner-realtime-onoff',
							JSON.stringify({
								customer: onCustomer,
								position: onPosition,
								wait: onWait,
								scale: scale - 0.1,
							})
						)
						setScale(prev => prev - 0.1)
					}}>
					<IconZoomOutArea size={20} />
				</Button>
				<Button
					size="small"
					onClick={() => {
						setScale(1)
						localStorage.setItem(
							'partner-realtime-onoff',
							JSON.stringify({
								customer: onCustomer,
								position: onPosition,
								wait: onWait,
								scale: 1,
							})
						)
					}}>
					<IconZoomReset size={20} />
				</Button>
			</Space.Compact>

			<Button
				size="small"
				onClick={() => {
					localStorage.removeItem('partner-realtime-layout')
					localStorage.removeItem('partner-realtime-onoff')
					window.location.reload()
				}}>
				레이아웃 초기화
			</Button>
		</View>
	)
}

export default React.memo(RealTimeTopNav)

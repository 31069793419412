import Grid, { GridRef, useCopyPaste, useSelection } from '@rowsncolumns/grid'
import { useExchangeStore } from 'app/hooks/trade/exchangeStore'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import { useCurrency } from 'app/hooks/trade/useCurrency'
import CanvasPrice from 'app/pages/ReferralPage/Columns/CanvasPrice'
import BigNumber from 'bignumber.js'
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from 'react'
import { Arrow, Group, Layer, Rect } from 'react-konva'
import { Text } from 'styles/canvas'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const CanvasTable = ({
	dataSource,
	ROW_HEIGHT = 20,
	columns,
	width = '100%',
	maxheight = 400,
	rowAction = (data: any) => {},
	selectedid,
	topSum = false,
	scale = 1,
}: any) => {
	const [sorttype, setSorttype] = React.useState('asc')
	const [sortcolumn, setSortcolumn] = React.useState('')

	const { canvasColor } = useContext(ThemeContext)

	const COLUMNCOUNT = columns?.length

	const [COLWIDTH, setCOLWIDTH] = React.useState(width / COLUMNCOUNT)
	const [DIFF, setDIFF] = React.useState(0)
	const [SCALEDIFF, setSCALEDIFF] = React.useState(0)

	let { getPrice } = useIndexPrice(5100)
	const { krwrate, getRate } = useCurrency()

	const { EPs } = useExchangeStore()

	useEffect(() => {
		gridRef.current?.resetAfterIndices({
			columnIndex: 0,
			rowIndex: 0,
		})
	}, [width, scale])

	useEffect(() => {
		const COLUMNCOUNT = columns?.length

		const sumwidth = columns?.reduce((acc: any, column: any) => {
			return acc + (column?.width || width / COLUMNCOUNT)
		}, 0)

		const diff = (width - sumwidth - 40) / COLUMNCOUNT

		const scalediff = (width / scale - sumwidth - 40) / COLUMNCOUNT

		setCOLWIDTH(width / COLUMNCOUNT)
		setDIFF(diff)

		setSCALEDIFF(scalediff)
	}, [columns, width])

	useEffect(() => {
		if (selectedid) {
			const index = dataSource.findIndex(
				(item: any) => +item.id === +selectedid
			)
			if (index > 0) {
				gridRef.current?.scrollToItem({
					rowIndex: index,
					columnIndex: 0,
				})
			}
		}
	}, [selectedid])

	const hover = useRef(-1)

	const headers = useMemo(
		() => columns?.map((column: any) => column?.title),
		[columns]
	)
	const sorters = useMemo(
		() => columns?.map((column: any) => column?.sorter),
		[columns]
	)
	const sums = useMemo(
		() => columns?.map((column: any) => column?.sum),
		[columns]
	)
	const avgs = useMemo(
		() => columns?.map((column: any) => column?.avg),
		[columns]
	)
	const isSum = useMemo(
		() => topSum && sums?.filter((sum: any) => sum).length > 0,
		[sums, topSum]
	)
	const ROWCOUNT = (dataSource?.length || 0) + (isSum ? 2 : 1)

	const dataref = useRef(dataSource)

	if (sortcolumn !== '') {
		dataSource.sort((a, b) => {
			const index = columns?.findIndex(
				column => column.dataIndex === sortcolumn
			)
			const sorter = sorters[index]

			if (!sorter) {
				return 0
			}
			if (sorttype === 'asc') {
				return sorter(a, b)
			} else {
				return sorter(b, a)
			}
		})
		dataref.current = dataSource
	} else {
		dataref.current = dataSource
	}

	//position dataIndex 가 있는지 확인
	if (dataref?.current?.[0]?.position) {
		dataref.current = dataref.current.map((item: any) => {
			let pnl = 0
			let losscut = 0
			let temptotalpnl = 0

			if (item?.position?.length === 0) {
				item.realtimebalance = 0
				item.losscut = 0
				return item
			}
			item?.position?.map((item: any) => {
				const EP = EPs.find(e => e.id === item.exchange_pairs_id)
				const currencyrate = getRate(EP?.currency)
				const rate = BigNumber(krwrate?.price || 0)
					.div(currencyrate?.price || 0)
					.toNumber()

				losscut = BigNumber(losscut)
					.plus(BigNumber(item?.amount).multipliedBy(EP?.losscut))
					.toNumber()

				if (item?.long_short === 'L') {
					pnl = BigNumber(getPrice(item?.exchange_pairs_id))
						.minus(item?.price)
						.multipliedBy(item?.amount)
						.multipliedBy(EP?.contractamt)
						.multipliedBy(rate)
						.toNumber()
				} else {
					pnl = BigNumber(item?.price)
						.minus(getPrice(item?.exchange_pairs_id))
						.multipliedBy(item?.amount)
						.multipliedBy(EP?.contractamt)
						.multipliedBy(rate)
						.toNumber()
				}

				temptotalpnl = BigNumber(temptotalpnl || 0)
					.plus(pnl || 0)
					.toNumber()
			})
			item.realtimebalance = +temptotalpnl || 0
			item.losscut = losscut

			item.realtime =
				(+item?.total_trade_price || 0) + (+item?.realtimebalance || 0)
			item.allbalance =
				(+item?.realtimebalance || 0) + (+item?.last_balance || 0) || 0

			return item
		})
	}

	if (!dataref.current[0]?.header) {
		if (isSum) {
			dataref.current = [
				{ header: true, id: 0 },
				{ sum: true },
				...dataref.current,
			]
		} else {
			dataref.current = [{ header: true, id: 0 }, ...dataref.current]
		}
	}

	const getValue = useCallback(
		({ rowIndex, columnIndex }) => {
			const item = dataref.current[rowIndex]
			const column = columnIndex
			const text = item[columns[column]?.dataIndex] || ''
			return text
		},
		[dataref.current, columns]
	)

	const gridRef = useRef<GridRef>(null)
	const { selections, activeCell, ...selectionProps } = useSelection({
		gridRef,
		rowCount: ROWCOUNT,
		columnCount: COLUMNCOUNT,
		getValue: getValue,
	})

	const { copy, paste, cut } = useCopyPaste({
		gridRef,
		selections,
		activeCell,
		getValue,
		getText: value => value,
		onCopy(selection) {
			const text = selection.map(({ bounds }) => {
				const { top, left, right, bottom } = bounds
				const rows: any = []
				for (let i = top; i <= bottom; i++) {
					const row: any = []
					for (let j = left; j <= right; j++) {
						row.push(getValue({ rowIndex: i, columnIndex: j }))
					}
					rows.push(row)
				}
				return rows
			})
			const textValue: any = text
				.map(row => row.map(cell => cell.join('\t')).join('\n'))
				.join('\n')
			navigator.clipboard.writeText(textValue)
		},
		onPaste: (rows, activeCell) => {},
		onCut: selection => {},
	})

	const CELLRENDRER = (props: any): any => {
		const item = dataref?.current[props.rowIndex]
		const column = props.columnIndex
		const render = columns[column]?.render
		const text = item?.[columns[column]?.dataIndex] || ''

		let sum = sums[column]
			? dataref.current?.reduce((acc, item) => {
					if (columns[column]?.dataIndex === 'fee_price') {
						return (
							acc -
							Math.abs(+item?.[columns[column]?.dataIndex] || 0)
						)
					} else if (columns[column]?.dataIndex === 'remain_amount') {
						return (
							acc +
							(+item?.['amount'] || 0) -
							(+item?.['remain_amount'] || 0)
						)
					} else {
						return acc + (+item?.[columns[column]?.dataIndex] || 0)
					}
			  }, 0)
			: 0

		const avg = avgs[column] ? sum / (ROWCOUNT - 2) : 0

		return (
			<Group
				key={props.rowIndex + '-' + props.columnIndex}
				onMouseOver={() => {
					hover.current = props.rowIndex
				}}
				onClick={() => {
					rowAction(item)
				}}
				width={props.width}
				height={ROW_HEIGHT}>
				{props.rowIndex === hover.current &&
					props.rowIndex > 1 &&
					props.columnIndex == 0 && (
						<Rect
							x={props.x}
							y={props.y}
							width={width}
							height={ROW_HEIGHT}
							fill={canvasColor.stroke}
							stroke={canvasColor.text}
							strokeWidth={0.5}
						/>
					)}
				{props.rowIndex === 0 ? (
					<Group
						onClick={() => {
							const column = columns[props.columnIndex]?.dataIndex
							if (sortcolumn === column) {
								setSorttype(sorttype === 'asc' ? 'desc' : 'asc')
							} else {
								setSortcolumn(column || '')
								setSorttype('asc')
							}
						}}>
						<Text
							x={props.x}
							y={props.y}
							width={props.width}
							height={props.height || ROW_HEIGHT}
							fill={canvasColor.text}
							fontSize={12}
							fontWeight={700}>
							{headers[column]}
						</Text>

						{columns[column]?.sorter && (
							<>
								<Arrow
									scale={{ x: 0.6, y: 0.7 }}
									x={props.x + props.width - 4}
									y={props.y + 4}
									fill={
										columns[column]?.dataIndex ===
											sortcolumn && sorttype === 'asc'
											? canvasColor.active
											: canvasColor.disabled
									}
									points={[8, 8, 0, 4, 0, 0]}
								/>
								<Arrow
									scale={{ x: 0.6, y: 0.7 }}
									x={props.x + props.width - 9}
									y={props.y + 15}
									fill={
										columns[column]?.dataIndex ===
											sortcolumn && sorttype === 'desc'
											? canvasColor.active
											: canvasColor.disabled
									}
									points={[0, 0, 8, 0, 8, 8]}
								/>
							</>
						)}
					</Group>
				) : isSum && props.rowIndex === 1 ? (
					<Group>
						{props.columnIndex === 0 && (
							<Rect
								x={props.x}
								y={props.y}
								width={width}
								height={ROW_HEIGHT}
								fill={canvasColor.hover}
								stroke={canvasColor.stroke}
								strokeWidth={2}
							/>
						)}
						{sums[column] ? (
							<CanvasPrice
								price={avg || sum}
								dp={0}
								color={true}
								p={{
									x: props.x,
									y: props.y,
									width: props.width,
									height: props.height || ROW_HEIGHT,
								}}></CanvasPrice>
						) : (
							<Text
								x={props.x}
								y={props.y}
								width={props.width}
								height={props.height || ROW_HEIGHT}>
								-
							</Text>
						)}
					</Group>
				) : (
					<>
						{render ? (
							render(text, item, props)
						) : (
							<Text
								align={columns[column]?.align || 'center'}
								x={props.x}
								y={props.y}
								width={props.width}
								height={props.height || ROW_HEIGHT}>
								{text}
							</Text>
						)}
					</>
				)}
			</Group>
		)
	}

	return (
		<View
			style={{
				zoom: scale,
				background: canvasColor.bg,
			}}>
			<Grid
				ref={gridRef}
				rowCount={ROWCOUNT}
				frozenRows={isSum ? 2 : 1}
				width={width / scale - 20}
				height={
					(ROWCOUNT == 1
						? 100
						: Math.min(ROW_HEIGHT * ROWCOUNT + 30, maxheight)) /
					scale
				}
				selections={selections}
				activeCell={activeCell}
				columnWidth={columnIndex => {
					return Math.max(
						(columns[columnIndex]?.width || COLWIDTH) + DIFF,
						(columns[columnIndex]?.width || COLWIDTH) + SCALEDIFF,
						columns[columnIndex]?.width || COLWIDTH,
						25
					)
				}}
				scrollbarSize={15}
				activeCellStrokeWidth={1}
				gridLineColor={canvasColor.stroke}
				columnCount={COLUMNCOUNT}
				rowHeight={rowIndex => ROW_HEIGHT / scale}
				showGridLines={true}
				showFrozenShadow={true}
				showScrollbar={true}
				selectionBorderColor={canvasColor.active}
				itemRenderer={CELLRENDRER}
				{...selectionProps}
			/>

			{ROWCOUNT === 2 && (
				<Layer x={0} y={0}>
					<Rect
						x={0}
						y={ROW_HEIGHT}
						width={width}
						height={ROW_HEIGHT}
						fill={canvasColor.bg}
					/>
					<Text
						x={width / 2 - 50}
						y={ROW_HEIGHT + 10}
						width={100}
						height={ROW_HEIGHT}
						fill={canvasColor.text}
						fontSize={12}>
						데이터가 없습니다.
					</Text>
				</Layer>
			)}
		</View>
	)
}

export default React.memo(CanvasTable)

import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { Rect } from 'react-konva'
import { Text } from 'styles/canvas'
import { ThemeContext } from 'theme/ThemeProvider'

const CanvasLongShort = ({
	long_short,
	p = { x: 0, y: 0, width: 50, height: 25 },
}) => {
	const { canvasColor } = useContext(ThemeContext)
	return (
		<>
			<Text
				x={p.x}
				y={p.y}
				width={p.width}
				height={p.height}
				fontSize={11}
				fontWeight={700}
				fill={long_short == 'L' ? canvasColor.plus : canvasColor.minus}>
				{long_short == 'L' ? '매수' : '매도'}
			</Text>
		</>
	)
}

export default React.memo(CanvasLongShort)

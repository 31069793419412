import dayjs from 'dayjs'
import React, { useEffect } from 'react'

import { Button, Modal, Space } from 'antd'
import CanvasTableAPI from 'app/components/TableAPI/CanvasTableAPI'
import { MView } from 'styles/mobilePageStyles'
import UserContent from '../../../components/UserContent'
import { useUserList } from '../../../hooks/trade/userList'
import GroupFilter from '../GroupFilter'
import { PartnerCustomerColumn } from '../PartnerCustomerColumn'
import SearchFilter from '../SearchFilter'

export function PartnerNewwindow() {
	const [modalshow, setModalshow] = React.useState<any>(false)

	const [group, setGroup] = React.useState<any>('*')

	//now 변경
	//다음날 06:59 까지는 전날로 계산
	const now = dayjs()
	if (now.hour() < 7) now.subtract(1, 'day')

	const [modal, setModal] = React.useState<any>(false)
	const [selectedData, setSelectedData] = React.useState<any>(undefined)
	const [level, setLevel] = React.useState<any>('1')

	const [search, setSearch] = React.useState(selectedData || '')
	const [search2, setSearch2] = React.useState(null)

	const [api, setApi] = React.useState<any>(``)

	const { userlist, sendwho } = useUserList()

	useEffect(() => {
		setApi(
			`/group/users?level=${level}&group=${group}
			&${search2 ? 'searchWord=' + search2 + '&' : ''}t=`
		)
		sendwho()
	}, [level, search2, group])

	useEffect(() => {
		sendwho()
	}, [])

	useEffect(() => {
		setModalshow(false)
		if (modalshow) {
			setTimeout(() => {
				setModal(true)
				setSelectedData(null)
			}, 20)
		} else {
		}
	}, [modalshow])

	return (
		<MView
			style={{
				width: '100%',
				alignContent: 'flex-start',
				justifyContent: 'flex-start',
				justifyItems: 'flex-start',
				alignItems: 'flex-start',
			}}>
			<CanvasTableAPI
				title={'고객관리'}
				defaultMaxData={100}
				className="plist"
				autorefresh={false}
				stylewidth={'100%'}
				loading={true}
				realtime={true}
				actionrefresh={() => {
					sendwho()
				}}
				extra={
					<Space.Compact size="small">
						<GroupFilter group={group} setGroup={setGroup} />
						<Button
							type={level === '*' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('*')
							}}>
							전체회원
						</Button>
						<Button
							type={level === '-1, 0' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('-1, 0')
							}}>
							승인대기
						</Button>
						<Button
							type={level === '1' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('1')
							}}>
							활동회원
						</Button>
						<Button
							type={level === '-8' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('-8')
							}}>
							모의신청
						</Button>
						<Button
							type={level === '6' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('6')
							}}>
							모의회원
						</Button>
						<Button
							type={level === '7' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('7')
							}}>
							페이크
						</Button>
						<Button
							type={level === '-2' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('-2')
							}}>
							거절회원
						</Button>
						<Button
							type={level === '-9' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('-9')
							}}>
							블랙회원
						</Button>
						<SearchFilter
							search={search}
							setSearch={setSearch}
							search2={search2}
							setSearch2={setSearch2}
						/>
					</Space.Compact>
				}
				columns={PartnerCustomerColumn}
				online={true}
				apikey={api}
			/>
			<Modal
				open={modal}
				onCancel={() => setModal(false)}
				width={'100%'}
				style={{ width: '90%' }}>
				<UserContent
					showOpen={modal}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					defaultfold={selectedData ? false : true}
				/>
			</Modal>
		</MView>
	)
}

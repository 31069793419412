import { useExchangeStore } from 'app/hooks/trade/exchangeStore'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import { useCurrency } from 'app/hooks/trade/useCurrency'
import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { Text } from 'styles/canvas'
import { ThemeContext } from 'theme/ThemeProvider'

const CanvasPNL = ({ data, p = { x: 0, y: 0, width: 100, height: 25 } }) => {
	const { canvasColor } = useContext(ThemeContext)

	let { getPrice } = useIndexPrice(data?.exchange_pairs_id)
	let { EPs } = useExchangeStore()
	let EP = EPs?.find((item: any) => item?.id === data?.exchange_pairs_id)

	let pnl = 0

	const currentprice = getPrice(data?.exchange_pairs_id)
	const { krwrate, getRate } = useCurrency()
	const currencyrate = getRate(EP?.currency)

	const rate = BigNumber(krwrate?.price || 0)
		.div(currencyrate?.price || 0)
		.toNumber()

	if (data?.long_short === 'L') {
		pnl = BigNumber(currentprice)
			.minus(data?.price)
			.multipliedBy(data?.amount)
			.multipliedBy(EP?.contractamt)
			.multipliedBy(rate)
			.toNumber()
	} else {
		pnl = BigNumber(data?.price)
			.minus(currentprice)
			.multipliedBy(data?.amount)
			.multipliedBy(EP?.contractamt)
			.multipliedBy(rate)
			.toNumber()
	}

	if (currentprice === 0) {
		pnl = 0
	}

	data.unrealizedpl = pnl

	return (
		<Text
			x={p.x}
			y={p.y}
			width={p.width}
			height={p.height}
			align="right"
			fontWeight={700}
			fill={
				data.unrealizedpl > 0
					? canvasColor.plus
					: data.unrealizedpl < 0
					? canvasColor.minus
					: canvasColor.text
			}>
			{`${data.unrealizedpl > 0 ? '+' : ''}${BigNumber(
				data.unrealizedpl
			).toFormat(0)}`}
		</Text>
	)
}

export default React.memo(CanvasPNL)

import React, { useCallback, useEffect, useState } from 'react'

import { Responsive, WidthProvider } from 'react-grid-layout'
import styled from 'styled-components'
import { ThemeContext } from 'theme/ThemeProvider'
import PartnerRealtimeCustomer from './PartnerRealtimeCustomer'
import PartnerRealtimePosition from './PartnerRealtimePosition'
import PartnerRealtimeWait from './PartnerRealtimeWait'
import { useWindowDimensions } from 'app/hooks'

const ResponsiveGridLayout = WidthProvider(Responsive)

const defaultlayout = [
	{
		i: 'customer',
		x: 0,
		y: 0,
		w: 8,
		h: 40,
		autoSize: true,
		isDraggable: true,
	},
	{
		i: 'position',
		x: 8,
		y: 0,
		w: 8,
		h: 20,
		isDraggable: true,
		autoSize: true,
	},
	{ i: 'wait', x: 8, y: 14, w: 8, h: 20, autoSize: true, isDraggable: true },
]

export function PartnerRealtimeDrag({
	group,
	onCustomer,
	onPosition,
	onWait,
	scale = 1,
}) {
	const [layout, setLayout] = useState<any>(defaultlayout)

	const [loading, setLoading] = useState(true)

	const [isDragging, setIsDragging] = useState(false)

	const { theme } = React.useContext(ThemeContext)

	const { width } = useWindowDimensions()

	useEffect(() => {
		const savedLayout = localStorage.getItem('partner-realtime-layout')
		if (savedLayout) {
			setLayout(JSON.parse(savedLayout))
		}

		setLoading(false)
	}, [])

	const onLayoutChange = useCallback(newLayout => {
		setIsDragging(false)
		setLayout(newLayout)
	}, [])

	//const { mutate: mutateGridSize } = useGridLayout()
	const handleResizeStop = useCallback(
		(layout, oldItem, newItem, placeholder, e, element) => {
			localStorage.setItem(
				'partner-realtime-layout',
				JSON.stringify(layout)
			)
		},
		[]
	)
	const handleResize = useCallback(
		(layout, oldItem, newItem, placeholder) => {
			setLayout(layout)
		},
		[]
	)

	const handleDrag = useCallback(layout => {
		setIsDragging(true)
		setLayout(layout)
	}, [])

	if (loading) return <div>Loading...</div>

	return (
		<RealtimeWrapper
			style={{
				width: '100%',
			}}>
			<ResponsiveGridLayout
				onResize={handleResize}
				onDrag={handleDrag}
				onDragStop={() => setIsDragging(false)}
				draggableHandle=".ant-card-head-title"
				className="layout"
				layouts={{
					lg: layout,
					md: layout,
					sm: layout,
					xs: layout,
					xxs: layout,
				}}
				isBounded={false}
				breakpoints={{
					lg: 1700,
					md: 1580,
					sm: 1200,
					xs: 768,
					xxs: 0,
				}}
				cols={{ lg: 16, md: 16, sm: 16, xs: 16, xxs: 16 }}
				rowHeight={20}
				isDraggable={true}
				isResizable={true}
				margin={[0, 0]}
				allowOverlap={false}
				measureBeforeMount={true}
				compactType={'vertical'}
				autoSize={true}
				style={{ width: '100%', height: '100%' }}
				onLayoutChange={onLayoutChange}
				onResizeStop={handleResizeStop}>
				{layout.map((item: any) => (
					<div
						key={item.i}
						onMouseOut={() => setIsDragging(false)}
						className={
							isDragging && item?.isDraggable ? 'dragging' : ''
						}
						style={{
							marginTop: 0,
							paddingBottom: 0,
							position: 'relative',
						}}>
						{onCustomer && item.i === 'customer' && (
							<PartnerRealtimeCustomer
								group={group}
								height={item.h * 20}
								scale={scale}
								width={width * (item.w / 16)}
							/>
						)}
						{onPosition && item.i === 'position' && (
							<PartnerRealtimePosition
								group={group}
								height={item.h * 20}
								scale={scale}
								width={width * (item.w / 16)}
							/>
						)}
						{onWait && item.i === 'wait' && (
							<PartnerRealtimeWait
								group={group}
								height={item.h * 20}
								width={width * (item.w / 16)}
								scale={scale}
							/>
						)}
					</div>
				))}
			</ResponsiveGridLayout>
		</RealtimeWrapper>
	)
}

export const RealtimeWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
	padding: 0;
	background-color: ${props => props.theme.COLORS.background};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	

	& .ant-card-head {
		border: 0px !important;

		background: ${props => props.theme.COLORS.descBox} !important;
	}

	& .ant-card {
		background-color: ${props => props.theme.COLORS.bgColor} !important;
		border: 0px !important;
	}

	& .ant-card-head-title {
		color: ${props => props.theme.COLORS.textw} !important;
		cursor: move;
		--webkit-app-region: drag;

		&:hover {
			color: ${props => props.theme.COLORS.textw} !important;
		}
	}

	& .ant-space-item {
		color: ${props => props.theme.COLORS.textw};
	}

	& .ant-card-body div {
		color: ${props => props.theme.COLORS.textw};
	}
`

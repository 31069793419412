import React from 'react'

import { Text } from 'styles/canvas'
import CanvasContracts from './Columns/CanvasContracts'
import CanvasLevel from './Columns/CanvasLevel'
import CanvasLongShort from './Columns/CanvasLongShort'
import CanvasPNL from './Columns/CanvasPNL'
import CanvasPrice from './Columns/CanvasPrice'
import CanvasRealtimePrice from './Columns/CanvasRealtimePrice'
import CanvasUserTag from './Columns/CanvasUserTag'

export const PartnerRealtimePositionColumn = (mutate = () => {}, showModal) => [
	{
		title: '번호',
		dataIndex: 'id',
		width: 60,
		align: 'center',
		sorter: (a, b) => a.id - b.id,
		render: (text, data, props) => (
			<Text
				x={props.x}
				y={props.y}
				width={props.width}
				height={props.height}>
				{text}
			</Text>
		),
	},
	{
		title: '그룹',
		dataIndex: 'referral_code',
		key: 'referral_code',
		sorter: (a, b) => a?.referral_code?.localeCompare(b.referral_code),
		width: 60,
	},
	{
		title: '등급',
		dataIndex: 'level',
		key: 'level',
		align: 'center',
		width: 30,
		sorter: (a, b) => a?.level - b?.level,
		render: (text, data, p) => {
			return <CanvasLevel level={text} p={p} />
		},
	},
	{
		title: '회원',
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		sorter: (a, b) => a?.fullname?.localeCompare(b.fullname),
		render: (text, data, p) => {
			return (
				<CanvasUserTag
					p={p}
					userid={data?.user_id}
					email={data?.email}
					phone={data?.phone}
					nickname={data?.fullname || data?.nickname}
					onClick={() => {
						window.open(
							`/partner/user/${data?.user_id}`,
							'_blank',
							'width=1750, height=800, left=0, top=0, location=no, toolbar=no, menubar=no, status=no, scrollbars=yes, resizable=yes'
						)
					}}
				/>
			)
		},
	},
	{
		title: '아이디',
		dataIndex: 'loginid',
		key: 'loginid',
		align: 'center',
		sorter: (a, b) => a?.loginid?.localeCompare(b.loginid),
		// render: (text, data) => {
		// 	return <Text>{text}</Text>
		// },
	},
	{
		title: '종목',
		align: 'center',
		width: 100,
		dataIndex: 'contracts',
		sorter: (a, b) => a.exchange_pairs_id - b.exchange_pairs_id,
		render: (text, data, p = { x: 0, y: 0, width: 100, height: 25 }) => (
			<CanvasContracts data={data} p={p} />
		),
	},
	{
		title: '구분',
		align: 'center',
		width: 35,
		dataIndex: 'long_short',
		sorter: (a, b) => a.long_short.localeCompare(b.long_short),
		render: (text, data, p = { x: 0, y: 0, width: 100, height: 25 }) => (
			<CanvasLongShort long_short={text} p={p} />
		),
	},
	{
		title: '수량',
		dataIndex: 'amount',
		align: 'center',
		sum: true,
		width: 30,
		sorter: (a, b) => a.amount - b.amount,
		render: (text, data, props) => (
			<CanvasPrice
				price={data?.long_short == 'L' ? text : -text}
				dp={0}
				color={true}
				p={props}
			/>
		),
	},
	{
		title: '평단가',
		align: 'center',
		dataIndex: 'price',
		sum: true,
		avg: true,
		sorter: (a, b) => a.price - b.price,
		render: (text, data, props) => (
			<CanvasPrice
				price={text}
				ep_id={data?.exchange_pairs_id}
				p={props}
			/>
		),
	},
	{
		title: '현재가',
		align: 'center',
		width: 60,
		dataIndex: 'price',
		render: (text, data, props) => (
			<CanvasRealtimePrice data={data} p={props} />
		),
	},
	{
		title: '평가손익',
		align: 'center',
		dataIndex: 'unrealizedpl',
		sum: true,
		sorter: (a, b) => a.unrealizedpl - b.unrealizedpl,
		render: (text, data, props) => {
			return <CanvasPNL data={data} p={props} />
		},
	},
	{
		title: '담보금',
		align: 'center',
		dataIndex: 'balance',
		sum: true,
		sorter: (a, b) => a.balance - b.balance,
		render: (text, data, props) => (
			<CanvasPrice price={text} p={props} dp={0} />
		),
	},
	{
		title: '오버',
		align: 'center',
		dataIndex: 'realize_dpl',
		width: 30,
		render: (text, data, props) => {
			return (
				<Text
					x={props.x}
					y={props.y}
					width={props.width}
					height={props.height || 20}>
					-
				</Text>
			)
		},
	},
]

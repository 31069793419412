import React from 'react'

import { Text } from 'styles/canvas'
import { typeFromLevelPartner } from 'utils/ciutils'
import CanvasOnline from './Columns/CanvasOnline'
import CanvasPrice from './Columns/CanvasPrice'
import CanvasTime from './Columns/CanvasTime'
import CanvasUserTag from './Columns/CanvasUserTag'

export const PartnerCustomerColumn = (
	mutate,
	selectedData,
	token,
	fold,
	shareMutate,
	getPrice,
	getRate,
	krwrate,
	EPs
) => [
	{
		title: '접속',
		dataIndex: 'client',
		key: 'client',
		align: 'center',
		width: 40,
		sorter: (a, b) => a?.client - b?.client,
		render: (text, data, p) => {
			return <CanvasOnline data={data} p={p} />
		},
	},
	{
		title: '그룹',
		dataIndex: 'referral_code',
		key: 'referral_code',
		width: 70,
	},
	{
		title: '상태',
		dataIndex: 'level',
		key: 'level',
		align: 'center',
		width: 70,
		sorter: (a, b) => a?.level - b?.level,
		render: (text, data, p) => {
			return (
				<Text
					x={p.x}
					y={p.y}
					width={p.width}
					height={p.height}
					fill={
						typeFromLevelPartner(text) == '접속불가'
							? '#f00'
							: typeFromLevelPartner(text) == '거래가능'
							? '#00af17'
							: typeFromLevelPartner(text) == '승인대기'
							? '#f90'
							: '#000'
					}>
					{typeFromLevelPartner(text)}
				</Text>
			)
		},
	},
	{
		title: '실명',
		dataIndex: 'fullname',
		key: 'fullname',
		align: 'center',
		sorter: (a, b) => a?.fullname?.localeCompare(b.fullname),
		render: (text, data, p) => {
			return (
				<CanvasUserTag
					p={p}
					userid={data?.id}
					email={data?.email}
					phone={data?.phone}
					nickname={data?.fullname || data?.nickname}
					onClick={() => {
						window.open(
							`/partner/user/${data?.id}`,
							'_blank',
							'width=1750, height=800, left=0, top=0, location=no, toolbar=no, menubar=no, status=no, scrollbars=yes, resizable=yes'
						)
					}}
				/>
			)
		},
	},
	{
		title: '아이디',
		dataIndex: 'loginid',
		key: 'loginid',
		align: 'center',
		sorter: (a, b) => a?.loginid?.localeCompare(b.loginid),
	},
	{
		title: '보유금',
		dataIndex: 'balance',
		key: 'balance',
		align: 'center',
		sum: true,
		sorter: (a, b) => a?.balance - b?.balance,
		render: (text, data, props) => {
			return (
				<CanvasPrice price={text || 0} color={false} dp={0} p={props} />
			)
		},
	},
	{
		title: '가입일',
		dataIndex: 'created_at',
		key: 'created_at',
		align: 'center',
		sorter: (a, b) =>
			new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
		render: (text, data, p) => {
			return <CanvasTime time={text} offset={9} p={p}/>
		},
	},
	{
		title: '마지막로그인',
		dataIndex: 'lastlogin_at',
		key: 'lastlogin_at',
		align: 'center',
		sorter: (a, b) =>
			new Date(a.lastlogin_at).getTime() -
			new Date(b.lastlogin_at).getTime(),
		render: (text, data, p) => {
			return <CanvasTime time={text} offset={9}  p={p}/>
		},
	},
]

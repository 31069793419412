import React from 'react'
import { Rect } from 'react-konva'
import { Text } from 'styles/canvas'
import { typeFromLevelSimple } from 'utils/ciutils'

const CanvasLevel = ({ level, p = { x: 0, y: 0, width: 100, height: 25 } }) => {
	return (
		<>
			{level == 1 && (
				<Rect
					x={p.x + 1}
					y={p.y + 1}
					width={p.width - 1}
					height={p.height - 1}
					fill={'#555555'}
				/>
			)}
			<Text
				x={p.x}
				y={p.y}
				width={p.width}
				height={p.height}
				fontWeight={700}
				fontSize={10}
				fill={level == 1 ? '#ffffff' : ''}>
				{typeFromLevelSimple(level)}
			</Text>
		</>
	)
}

export default React.memo(CanvasLevel)

import { useExchangeStore } from 'app/hooks/trade/exchangeStore'
import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { Text } from 'styles/canvas'
import { ThemeContext } from 'theme/ThemeProvider'

const CanvasPrice = ({
	ep_id = undefined,
	price = 0,
	dp = undefined,
	color = false,
	p = { x: 0, y: 0, width: 100, height: 25 },
	text = '',
	align = 'right',
}: any) => {
	const { EPs } = useExchangeStore()
	const EP = EPs?.find(ep => ep?.id === ep_id)
	const { canvasColor } = useContext(ThemeContext)
	return (
		<Text
			x={p.x}
			y={p.y}
			width={p.width}
			fontWeight={700}
			height={p.height}
			align={align}
			fill={
				color
					? price > 0
						? canvasColor.plus
						: price < 0
						? canvasColor.minus
						: canvasColor.text
					: canvasColor.text
			}>
			{`${
				price == 0
					? '-'
					: dp != undefined
					? BigNumber(price || 0).toFormat(dp)
					: BigNumber(price || 0).toFormat(EP?.dp)
			}${text}`}
		</Text>
	)
}

export default React.memo(CanvasPrice)

import { COLORS } from 'app/constants'
import CanvasPrice from 'app/pages/ReferralPage/Columns/CanvasPrice'
import CanvasTime from 'app/pages/ReferralPage/Columns/CanvasTime'
import BigNumber from 'bignumber.js'
import React from 'react'
import { Group, Rect } from 'react-konva'

import { Text } from 'styles/canvas'
export const PartnerTradelistColumn = (
	mutate,
	selectedData,
	token,
	fold,
	shareMutate,
	getPrice,
	getRate,
	krwrate,
	EPs,
	coinglobal
) => [
	{
		title: '주문일자',
		dataIndex: 'created_at',
		sorter: (a, b) =>
			new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
		align: 'center',
		width: 100,
		render: (text, record, p) => {
			return (
				<CanvasTime
					time={record?.created_at}
					p={p}
					offset={9}
					fontSize={12}
				/>
			)
		},
	},
	{
		title: '주문',
		dataIndex: 'id',
		sorter: (a, b) => a.id - b.id,
		width: 50,
		align: 'center',
	},
	{
		title: '원주문',
		dataIndex: 'original_id',
		sorter: (a, b) => a.original_id - b.original_id,
		width: 50,
		align: 'center',
	},
	{
		title: '종목코드',
		dataIndex: 'pairs',
		sorter: (a, b) => a.pairs - b.pairs,
		align: 'center',
		width: 60,
	},
	{
		title: '종목명',
		dataIndex: 'groupname',
		sorter: (a, b) => (a.groupname > b.groupname ? 1 : -1),
		align: 'center',
	},
	{
		title: '거래소',
		dataIndex: 'exchange_code',
		sorter: (a, b) => (a.exchange_code > b.exchange_code ? 1 : -1),
		width: 40,
		align: 'center',
	},

	{
		title: '매매구분',
		dataIndex: 'open_close',
		sorter: (a, b) => (a.open_close > b.open_close ? 1 : -1),
		align: 'center',
		width: 50,
		render: (text, record, p) => {
			return (
				<Text
					x={p.x}
					y={p.y}
					width={p.width}
					height={p.height}
					fill={
						text == 'O'
							? (record?.pnl_price || 0) != 0
								? COLORS.lightGreen
								: COLORS.black
							: COLORS.lightRed
					}>
					{record?.status == 3
						? record?.modi
							? '정정'
							: '취소'
						: text == 'O' || record?.status == 1
						? (record?.pnl_price || 0) != 0
							? '전환'
							: '진입'
						: '청산'}
				</Text>
			)
		},
	},
	{
		title: '주문구분',
		dataIndex: 'long_short',
		sorter: (a, b) => (a?.long_short > b?.long_short ? 1 : -1),
		align: 'center',
		width: 50,
		render: (text, record, p) => {
			return (
				<Text
					x={p.x}
					y={p.y}
					width={p.width}
					height={p.height}
					fill={text == 'S' ? COLORS.lightGreen : COLORS.lightRed}>
					{text == 'L' ? '매수' : '매도'}
				</Text>
			)
		},
	},
	{
		title: '구분',
		dataIndex: 'reduce_only',
		align: 'center',
		width: 50,
		sorter: (a, b) => (a.limit_market > b.limit_market ? 1 : -1),
		render: (text, record, p) => {
			return (
				<Text x={p.x} y={p.y} width={p.width} height={p.height}>
					{text == 1
						? 'MIT'
						: record?.limit_market == 'M'
						? '시장가'
						: '지정가'}
				</Text>
			)
		},
	},
	{
		title: '주문수량',
		dataIndex: 'amount',
		sorter: (a, b) => a.amount - b.amount,
		align: 'center',
		sum: true,
		width: 30,
		render: (text, record, p) => {
			return (
				<Text x={p.x} y={p.y} width={p.width} height={p.height}>
					{new BigNumber(text).toFormat(0)}
				</Text>
			)
		},
	},
	{
		title: '주문단가',
		dataIndex: 'order_price',
		sorter: (a, b) => a.order_price - b.order_price,
		align: 'center',
		render: (text, record, p) => {
			const EP = EPs?.find(e => e.id == record?.exchange_pairs_id)
			return (
				<CanvasPrice
					price={
						record?.limit_market == 'M'
							? record?.trade_price || 0
							: text
					}
					p={p}
					dp={record?.dp || EP?.dp}
					color={false}
				/>
			)
		},
	},
	{
		title: '체결수량',
		dataIndex: 'remain_amount',
		align: 'center',
		sum: true,
		width: 30,
		sorter: (a, b) => a.remain_amount - b.remain_amount,
		render: (text, record, p) => {
			return (
				<Text x={p.x} y={p.y} width={p.width} height={p.height}>
					{new BigNumber(record?.amount)
						.minus(record?.remain_amount)
						.multipliedBy(record?.leverage)
						.toFormat(0)}
				</Text>
			)
		},
	},
	{
		title: '체결단가',
		dataIndex: 'trade_price',
		sorter: (a, b) => a.trade_price - b.trade_price,
		align: 'center',
		render: (text, record, p) => {
			const EP = EPs?.find(e => e.id == record?.exchange_pairs_id)
			return (
				<CanvasPrice
					price={text}
					p={p}
					dp={record?.dp || EP?.dp}
					color={false}
				/>
			)
		},
	},
	{
		title: '수수료',
		dataIndex: 'fee_price',
		sorter: (a, b) => a.fee_price - b.fee_price,
		align: 'center',
		sum: true,
		render: (text, record, p) => {
			return (
				<CanvasPrice
					price={-Math.abs(text)}
					dp={0}
					coinglobal={coinglobal}
					color={true}
					p={p}
				/>
			)
		},
	},
	{
		title: '매매손익',
		dataIndex: 'pnl_price',
		sorter: (a, b) => a.pnl_price - b.pnl_price,
		align: 'center',
		sum: true,
		render: (text, record, p) => {
			console.log('record', text)
			return (
				<CanvasPrice
					price={text}
					dp={0}
					coinglobal={coinglobal}
					color={true}
					p={p}
				/>
			)
		},
	},
	{
		title: '실잔액',
		dataIndex: 'balance_futures',
		sorter: (a, b) => a.balance_futures - b.balance_futures,
		align: 'center',
		render: (text, record, p) => {
			return (
				<CanvasPrice
					price={text}
					dp={0}
					coinglobal={coinglobal}
					color={false}
					p={p}
				/>
			)
		},
	},
	{
		title: '체결일시',
		dataIndex: 'traded_at',
		sorter: (a, b) =>
			new Date(a.traded_at).getTime() - new Date(b.traded_at).getTime(),
		align: 'center',
		width: 100,
		render: (text, record, p) => {
			return <CanvasTime time={record?.traded_at} p={p} offset={9} />
		},
	},
	{
		title: '플랫폼',
		dataIndex: 'platform',
		sorter: (a, b) => a.platform - b.platform,
		align: 'center',
		width: 30,
		render: (text, record, p) => {
			return text == 0 ? (
				<>
					<Rect
						x={p.x + 1}
						y={p.y + 1}
						width={p.width - 1}
						height={p.height - 1}
						fill={'#5877ff'}
					/>
					<Text
						x={p.x}
						y={p.y}
						width={p.width}
						height={p.height}
						fontSize={11}
						fontWeight={700}
						fill={'#ffffff'}>
						WTS
					</Text>
				</>
			) : text == 1 ? (
				<>
					<Rect
						x={p.x + 1}
						y={p.y + 1}
						width={p.width - 1}
						height={p.height - 1}
						fill={'#00c79c'}
					/>
					<Text
						x={p.x}
						y={p.y}
						width={p.width}
						height={p.height}
						fontSize={11}
						fontWeight={700}
						fill={'#fff'}>
						MTS
					</Text>
				</>
			) : text == 2 ? (
				<>
					<Rect
						x={p.x + 1}
						y={p.y + 1}
						width={p.width - 1}
						height={p.height - 1}
						fill={'#ffae00'}
					/>
					<Text
						x={p.x}
						y={p.y}
						width={p.width}
						height={p.height}
						fontSize={11}
						fontWeight={700}
						fill={'#ffffff'}>
						HTS
					</Text>
				</>
			) : (
				<Text
					x={p.x}
					y={p.y}
					width={p.width}
					height={p.height}
					fontSize={11}
					fontWeight={700}
					fill={'#ff0000'}>
					서버
				</Text>
			)
		},
	},
	{
		title: '비고',
		dataIndex: 'status',
		align: 'left',
		render: (text, record, p) => {
			const item: any = []
			if (record?.f == 1) {
				item.push({ color: '#F05551', text: '로스컷' })
			} else if (record?.f == 3) {
				item.push({ color: '#F05551', text: '장마감' })
			} else if (record?.f == 5) {
				item.push({ color: '#311b1b', text: 'MIT' })
			} else if (record?.f == 4) {
				item.push({ color: '#F05551', text: '오버부족' })
			} else {
				item.push({
					color:
						text == 2
							? '#007EDA'
							: text == 3
							? '#000000'
							: 'default',
					text:
						text == 1
							? '미체결'
							: text == 2
							? '체결'
							: text == 3
							? '취소'
							: '-',
				})
			}
			if (record?.reduce_position == 5) {
				item.push({ color: '#272727', text: 'MIT' })
			} else if (record?.reduce_position == 2) {
				item.push({ color: '#F05551', text: '익절' })
			} else if (record?.reduce_position == 6) {
				item.push({ color: '#007EDA', text: '손절' })
			} else if (record?.reduce_position == 4) {
				item.push({ color: '#F05551', text: '전체청산' })
			} else if (record?.reduce_position == 3) {
				item.push({ color: '#F05551', text: '현종목청산' })
			}

			const width = p.width / item.length

			return (
				<Group>
					{/*//포지션 감소 여부 5 MIT  2 익절 6 손절  1 청산주문 0 일반주문 4: 전체청산 3: 현종목청산*/}
					{item.map((i, idx) => (
						<Text
							x={p.x + width * idx}
							y={p.y + 3}
							width={width}
							height={p.height - 3}
							key={idx}
							fontSize={11}
							fontWeight={700}
							fill={'#ffffff'}
							bgColor={i.color}>
							{i.text}
						</Text>
					))}
				</Group>
			)
		},
	},
]

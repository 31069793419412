import { IconUserCircle } from '@tabler/icons-react'
import { Button, Popover, Space, message } from 'antd'
import { AppLogo } from 'app/components'
import { CButton } from 'app/components/Common'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'theme/ThemeProvider'
import { Text, View } from '../../../../styles/reactnative'
import { COINHIDE, COIN_URL } from '../../../constants/APIKEYS'
import { useToken } from '../../../hooks'
import { useMyProfile } from '../../../hooks/user/myprofile'
import BtnLanguageChange from '../../../organisms/Header/BtnLanguageChange'
import DarkModeToggle from '../../../organisms/Header/DarkModeToggle/mobile'
import MobileToggle from '../../../organisms/Header/MobileToggle/mobile'
import MButton from '../MButton'
import { HeaderSection } from './styles'

const Header = ({ showSelect = false, setShowSelect = e => {} }) => {
	const { textw, raise, bgColor, point } =
		useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const { data: token, mutate } = useToken()
	const navigation = useHistory()
	const { data: profile, mutate: mutateProfile } = useMyProfile()

	const { t } = useTranslation()

	useEffect(() => {

		if (profile?.level == -9 || profile?.level == -2) {
			message.error('접속 권한이 없습니다.')
			mutateProfile(null)
			mutate(null)
		}
	}, [profile?.level])

	return (
		<>
			<HeaderSection
				isDarkMode={isDarkMode}
				style={{
					justifyContent: 'space-between',
					height: 50,
					zIndex: 999,
				}}>
				<AppLogo
					width={'100'}
					style={{ marginRight: 5 }}
					onPress={() => {
						navigation.push('/')
					}}
				/>
				<View
					style={{
						flex: 1,
						flexDirection: 'row',
						justifyContent: 'flex-start',
					}}>
					{!COINHIDE && (
						<Space.Compact size="small">
							<Button
								type="default"
								size="small"
								onClick={() => {
									window.location.href =
										COIN_URL +
										'?ssotoken=' +
										token +
										'&darkmode=' +
										(isDarkMode ? 'dark' : 'light')
								}}
								style={{
									background: !isDarkMode
										? '#121212'
										: '#f6f6f6',
									color: !isDarkMode ? '#AEAEAE' : '#828282',
									border: '1px solid #AEAEAE',
									width: 80,
									fontSize: 12,
								}}>
								{t('COINFUTURES2')}
							</Button>
							<Button
								type="primary"
								size="small"
								style={{
									background: !isDarkMode
										? '#2675AE'
										: '#2675AE',
									fontSize: 12,
								}}
								onClick={() => {
									window.location.href = '/'
									setShowSelect(false)
								}}>
								{t('GLOBAL2')}
							</Button>
						</Space.Compact>
					)}
				</View>
				<View style={{ flexDirection: 'row' }}>
					<DarkModeToggle size={18} />
					<MobileToggle size={18} />
					<BtnLanguageChange label={'Language'} />
					{profile?.level == 6 && (
						<Text
							style={{
								position: 'absolute',
								background: 'black',
								color: 'white',
								borderRadius: 5,
								padding: '3px 5px',
								top: 0,
								right: 0,
							}}>
							{profile?.level == 6 ? '모의' : ''}
						</Text>
					)}
					{token && (
						<Popover
							trigger={'click'}
							content={
								<View style={{ minWidth: 100 }}>
									<Text
										style={{
											color: point,
											fontWeight: 'bold',
											marginBottom: 20,
										}}>
										{profile?.nickname} (400{profile?.id})
									</Text>
									<MButton
										title={t('logout')}
										style={{
											width: '100%',
											alignItems: 'flex-start',
											paddingBlock: 10,
											color: 'black',
										}}
										textStyle={{
											color: 'black',
											fontSize: 16,
										}}
										onClick={() => {
											mutate(null)
											setTimeout(
												() =>
													(window.location.href =
														'/?msg=' +
														decodeURIComponent(
															'로그아웃 되었습니다.'
														)),
												100
											)
										}}
									/>
								</View>
							}>
							<CButton
								icon={
									<IconUserCircle size={20} color={textw} />
								}
							/>
						</Popover>
					)}
				</View>

				{/* 
				<View
					style={{
						position: 'fixed',
						display: showSelect ? 'flex' : 'none',
						zIndex: 1000,
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: bgColor,
						opacity: 0.95,
					}}>
					<AppLogo
						width={180}
						onPress={() => {
							setShowSelect(false)
						}}
					/>
					<ContentSplit
						style={{ height: 300, flex: 0.5, maxWidth: 1000 }}>
						<Button
							type="primary"
							size="large"
							onClick={() => {
								window.location.href =
									COIN_URL +
									'?ssotoken=' +
									token +
									'&darkmode=' +
									(!isDarkMode ? 'dark' : 'light')
							}}
							style={{
								width: '90%',
								maxWidth: 400,
								minHeight: 150,
								fontSize: 20,
							}}>
							코인선물
						</Button>
						<Button
							type="primary"
							size="large"
							style={{
								width: '90%',
								maxWidth: 400,
								minHeight: 150,
								fontSize: 20,
								border: '3px solid #555',
							}}
							onClick={() => {
								setShowSelect(false)
							}}
							danger>
							해외선물
						</Button>
					</ContentSplit>

					<Text style={{ color: raise, fontSize: 16, marginTop: 25 }}>
						※ 상단의 로고를 누르시면 현재 선택화면이 다시
						나타납니다.
					</Text>
				</View> */}
			</HeaderSection>
		</>
	)
}

export default Header

import React, { useState, useContext } from 'react'
import { IconSettings } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { HeaderContainer, HeaderButtonWrap } from './styles'
import { useHeader, useToken, useWindowDimensions } from 'app/hooks'
import { useCheckTradePage } from 'utils/ExpensiveFunction'

import HeaderButton from './HeaderButton'
import AuthHeaderMenu from './AuthHeaderMenu'
import DarkModeToggle from './DarkModeToggle'
import { ThemeContext } from 'theme/ThemeProvider'
import { CModal, CToast } from 'app/components/Common'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import { Button, Modal, Space, Tabs, message } from 'antd'
import GDeposit from './GDeposit'
import GWithdraw from './GWithdraw'
import DWList from './DWList'
import TradeList from './TradeList'
import Notice from './Notice'
import CalcList from './CalcList'
import { useTradeStore } from '../../hooks/trade/tradeStore'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import { COINHIDE, COIN_URL, ENGONLY } from '../../constants/APIKEYS'
import useSWR from 'swr'
import API from '../../../utils/api'
import MobileToggle from './MobileToggle'
import { View } from '../../../styles/reactnative'
import DerivativesHeader from '../Trade/TradeTransaction/DerivativesTransaction/DerivativesHeader'
import BtnLanguageChange from './BtnLanguageChange'
import { AppLogo, TransferModal } from '../../components'
import TicketModal from '../TicketModal'
import VersionChecker from './VersionChecker'
import MyLog from './MyLog'

const Header = ({ showSelect = false, setShowSelect = show => { } }) => {
	const { width } = useWindowDimensions()
	const {
		textw,
		cardOpacity,
		bgColor,
		stroke,
		Secondary,
		Tertiary,
		point,
		raise,
	} = useContext(ThemeContext).theme.COLORS
	const { isDarkMode, isMobile : mobilecheck } = useContext(ThemeContext)
	const navigate = useHistory()

	const { data: headerStore, change } = useHeader()

	const { data: token, mutate: mutateToken } = useToken()

	const [displayNav, setDisplayNav] = useState('none')
	const { t } = useTranslation()

	const nav = (url: string) => navigate.push(url)

	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	const { EPs } = useExchangeStore()

	const isTrade = useCheckTradePage()

	const headercheck = /iphone|ipad|ipod|android|mobile/i.test(
		navigator.userAgent?.toLowerCase()
	) 

	const isMobile = mobilecheck || headercheck

	const { data } = useSWR(
		token ? 'SECURECHECK' : null,
		async () => {
			const swrdata = await API.get(
				'/auth/secure?ismobile=' + (isMobile ? 1 : 0),
				token + '',
				{},
				false
			)
			return swrdata?.result
		},
		{ refreshInterval: 1000 * 30 }
	)

	if (token && data?.code != 'OK') {
		if (!isMobile) {
			message.error('보안프로그램이 실행중이 아닙니다.')
			mutateToken(null)
			window.location.href = ENGONLY ? 'There is a security program update. Please refresh.' : '/logout?msg=보안프로그램이 실행중이 아닙니다.'
		}
	}

	if (data?.ver != data?.recentver) {
		if (!isMobile) {
			message.error(
				'보안프로그램 업데이트가 있습니다. 새로고침 해주세요.'
			)
			window.location.href =
				'/logout?msg=보안프로그램 업데이트가 있습니다.'
		}
	}

	return (
		<>
			<VersionChecker />
			<Modal
				open={headerStore?.logmodal}
				onCancel={() =>
					change({
						logmodal: false,
					})
				}
				title={t('log')}
				footer={null}
				width={900}>
				<MyLog />
			</Modal>
			<HeaderContainer
				background={bgColor}
				style={{
					height: isTrade ? 45 : 80,
					borderBottom: '1px solid ' + stroke,
				}}>
				<HeaderButtonWrap display={displayNav}>
					{/* Menu Left Side */}
					{token && (
						<View
							style={{
								flexDirection: 'row',
								minWidth: token ? 550 : 0,
								justifyContent: 'flex-start',
							}}>
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}>
								<HeaderButton
									label={t('trade')}
									onPress={() => {
										mutateTradeStore({
											ep_id: EPs[0]?.id,
											...EPs[0],
										})
										nav('/trade/' + EPs[0]?.id)
									}}
								/>
								<HeaderButton
									label={t('Deposit/withdrawal application')}
									onPress={() => { }}
									data={[
										{
											title: ENGONLY ? 'Deposit' : '입금신청',
											onClick: () =>
												mutateModalStore({
													...modalStore,
													gDeposit: true,
													gDepositType: 'D',
												}),
										},
										{
											title: ENGONLY ? 'Withdraw' : '출금신청',
											onClick: () =>
												mutateModalStore({
													...modalStore,
													gDeposit: true,
													gDepositType: 'W',
												}),
										},
									]}
								/>
								<HeaderButton
									label={t('depositandwithdrawal')}
									onPress={() =>
										mutateModalStore({
											...modalStore,
											depositList: true,
											depositType: '*',
										})
									}
								/>
								<HeaderButton
									label={t('tradehistory')}
									onPress={() =>
										mutateModalStore({
											...modalStore,
											tradeList: true,
										})
									}
								/>
								<HeaderButton
									label={t('profitandloss')}
									onPress={() =>
										mutateModalStore({
											...modalStore,
											tradepnl: true,
										})
									}
								/>
								<HeaderButton
									label={t('QNA')}
									onPress={() =>
										mutateModalStore({
											...modalStore,
											qna: true,
										})
									}
								/>
								{!COINHIDE && (
									<HeaderButton
										label={t('notice')}
										onPress={() =>
											mutateModalStore({
												...modalStore,
												notice: true,
											})
										}
									/>
								)}
							</View>
						</View>
					)}
					{/* <Popover
						open={showSelect}
						placement="bottom"
						content={
							<View style={{ padding: '5px' }}>
								<View
									style={{
										position: 'absolute',
										right: 3,
										top: 3,
									}}>
									<IconX
										color="#888"
										style={{
											float: 'right',
											cursor: 'pointer',
										}}
										size={18}
										onClick={() => {
											setShowSelect(false)
										}}
									/>
								</View>
							</View>
						}> */}
					<AppLogo
						width={100}
						style={{
							cursor: 'pointer',
						}}
						onPress={() => {
							nav('/')
							//setShowSelect(!showSelect)
						}}
					/>
					<View
						style={{
							position: token ? 'absolute' : 'static',
							marginLeft: token ? (isTrade ? 210 : 250) : 10,
							flexDirection: 'row',
						}}>
						{!COINHIDE && (
							<Space.Compact size="small">
								<Button
									type="default"
									size={'small'}
									onClick={() => {
										window.location.href =
											COIN_URL +
											'?ssotoken=' +
											token +
											'&darkmode=' +
											(isDarkMode ? 'dark' : 'light')
									}}
									style={{
										background: !isDarkMode
											? '#121212'
											: '#f6f6f6',
										color: !isDarkMode
											? '#AEAEAE'
											: '#828282',
										border: '1px solid #AEAEAE',
									}}>
									{t('COINFUTURES')}
								</Button>
								<Button
									type="primary"
									size={'small'}
									style={{
										background: !isDarkMode
											? '#2675AE'
											: '#2675AE',
									}}
									onClick={() => {
										window.location.href = '/'
										setShowSelect(false)
									}}>
									{t('GLOBAL')}
								</Button>
							</Space.Compact>
						)}
						{!token && (
							<View
								style={{
									marginLeft: 10,
									flexDirection: 'row',
								}}>
								<DarkModeToggle />
								{!ENGONLY &&
									<BtnLanguageChange label={'Language'} />}
							</View>
						)}
					</View>
					<View
						style={{
							flexDirection: 'row',
							minWidth: token ? (isTrade ? 610 : 580) : 0,
							justifyContent: 'flex-end',
						}}>
						{token ? <AuthHeaderMenu /> : null}
						{token && (
							<>
								<View
									style={{
										margin: '0 5px',
										color: Tertiary,
									}}>
									|
								</View>

								<MobileToggle />
								<DarkModeToggle />
								{!ENGONLY && <BtnLanguageChange label={'Language'} />}
								{isTrade && (
									<View
										style={{
											cursor: 'pointer',
											marginLeft: 20,
										}}
										onClick={() => {
											mutateModalStore({
												...modalStore,
												settings: true,
											})
										}}>
										<IconSettings
											size={20}
											style={{ color: point }}
										/>
									</View>
								)}
							</>
						)}
						{/* <SessionDisplay /> */}
					</View>
				</HeaderButtonWrap>

				<CModal
					visible={modalStore?.transferModal}
					onClose={() => {
						mutateModalStore({
							...modalStore,
							transferModal: false,
						})
					}}>
					<TransferModal
						setShowModal={value => {
							mutateModalStore({
								...modalStore,
								transferModal: value,
							})
						}}
					/>
				</CModal>
				<CToast />
			</HeaderContainer>

			<CToast />

			<Modal
				open={modalStore?.settings}
				rootClassName={'modal' + (isDarkMode ? 'light' : 'dark')}
				bodyStyle={{ backgroundColor: cardOpacity, borderRadius: 10 }}
				title={t('settings')}
				onCancel={() =>
					mutateModalStore({ ...modalStore, settings: false })
				}
				onOk={() =>
					mutateModalStore({ ...modalStore, settings: false })
				}
				width={670}
				footer={null}
				centered={false}>
				<DerivativesHeader />
			</Modal>

			<Modal
				open={modalStore?.gDeposit && token}
				title={t('Deposit/withdrawal application')}
				onCancel={() =>
					mutateModalStore({ ...modalStore, gDeposit: false })
				}
				onOk={() =>
					mutateModalStore({ ...modalStore, gDeposit: false })
				}
				width={910}
				footer={null}
				centered={true}>
				<Tabs
					activeKey={modalStore?.gDepositType}
					onChange={key =>
						mutateModalStore({ ...modalStore, gDepositType: key })
					}>
					<Tabs.TabPane tab="입금" key="D">
						<GDeposit />
					</Tabs.TabPane>
					<Tabs.TabPane tab="출금" key="W">
						<GWithdraw />
					</Tabs.TabPane>
				</Tabs>
			</Modal>

			<Modal
				open={modalStore?.depositList && token}
				title={t('depositandwithdrawal')}
				onCancel={() =>
					mutateModalStore({ ...modalStore, depositList: false })
				}
				onOk={() =>
					mutateModalStore({ ...modalStore, depositList: false })
				}
				width={'80%'}
				footer={null}
				centered={true}>
				<DWList />
			</Modal>
			{/* Soe Thet */}
			<Modal
				open={modalStore?.tradeList && token}
				title={t('tradehistory')}
				// transitionName={'fade'}
				// maskClosable={true}
				// maskTransitionName="fade"
				destroyOnClose={true}
				onCancel={() =>
					mutateModalStore({ ...modalStore, tradeList: false })
				}
				onOk={() =>
					mutateModalStore({ ...modalStore, tradeList: false })
				}
				width={'95%'}
				bodyStyle={{ maxHeight: '95%', height: '95%' }}
				footer={null}
				centered={true}>
				<TradeList />
			</Modal>

			<Modal
				open={modalStore?.tradepnl}
				title={t('profitandloss')}
				onCancel={() =>
					mutateModalStore({ ...modalStore, tradepnl: false })
				}
				onOk={() =>
					mutateModalStore({ ...modalStore, tradepnl: false })
				}
				width={'80%'}
				footer={null}
				centered={true}>
				<CalcList />
			</Modal>

			<TicketModal />

			<Modal
				open={modalStore?.notice}
				rootClassName={'modal light'}
				title={t('notice')}
				onCancel={() =>
					mutateModalStore({ ...modalStore, notice: false })
				}
				onOk={() => mutateModalStore({ ...modalStore, notice: false })}
				width={'60%'}
				footer={null}
				centered={true}>
				<Notice />
			</Modal>
		</>
	)
}

export default React.memo(Header)

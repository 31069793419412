import { useUserList } from 'app/hooks/trade/userList'
import React from 'react'
import { Text } from 'styles/canvas'

const CanvasOnline = ({ data, p = { x: 0, y: 0, width: 100, height: 25 } }) => {
	const { userlist } = useUserList()
	const user = userlist.find((user: any) => user == data?.id)

	if (!data) return null
	data.client = user ? 1 : 0

	return (
		<Text
			x={p.x}
			y={p.y}
			width={p.width}
			height={p.height}
			fontWeight={user ? 700 : 400}
			fill={user ? '#00af17' : '#f00'}>
			{`${user ? '접속' : '-'}`}
		</Text>
	)
}

export default React.memo(CanvasOnline, (prev, next) => {
	return prev.data?.id === next.data?.id
})

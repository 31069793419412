import { OrderedListOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import useSWR from 'swr'
import API from '../../../utils/api'
import { useToken, useWindowDimensions } from '../../hooks'
import { goTo } from '../../hooks/navigation'

import { IconX } from '@tabler/icons-react'
import { useExchangeStore } from 'app/hooks/trade/exchangeStore'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import { useTradeSocket } from 'app/hooks/trade/tradeSocket'
import { useCurrency } from 'app/hooks/trade/useCurrency'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { API_COINURL } from '../../constants/APIKEYS'
import CanvasTable from '../CanvasTable'
import TableFooter from './TableFooter'

const CanvasTableAPI = ({
	title,
	apikey,
	extra = null,
	columns,
	fullheight = false,
	minusHeight = 100,
	height = null,
	width = null,
	pagination = true,
	detail = '',
	datesearch = false,
	action = () => {},
	actionmodal = () => {},
	defaultMaxData = 20,
	actionrefresh = () => {},
	footer = 0,
	autorefresh = false,
	fold = false,
	onlyonline = false,
	selectedid = '',
	rowAction = () => {},
	coinglobal = 'G',
	realtime = false,
	hideIcon = false,
	closeAction = null,
	topSum = false,
	scale = 1,
}: any) => {
	const { data: token } = useToken()
	const nav = useHistory()

	const socket = useTradeSocket()
	const { joinGroup, leaveGroup } = socket

	const now = dayjs()

	const shareMutate = () => {}

	const [page, setPage] = useState(1)
	const [maxData, setMaxData] = useState(defaultMaxData)
	const [showModal, setShowModal] = useState(false)

	let { getPrice } = useIndexPrice(5100)
	const { krwrate, getRate } = useCurrency()

	const { EPs } = useExchangeStore()

	const [startDate, setStartDate] = useState<any>(now.format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState<any>(now.format('YYYY-MM-DD'))

	//00시 ~ 06시까지는 전날로 계산

	useEffect(() => {
		if (now.hour() < 6) {
			setStartDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
			setEndDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
		}
	}, [])

	const ref = React.useRef<HTMLDivElement>(null)
	const { width: WIDTH, height: HEIGHT } = useWindowDimensions()

	const [parentWidth, setParentWidth] = useState(0)
	const [parentHeight, setParentHeight] = useState(0)
	const [selectedData, setSelectedData] = useState<any>(null)

	useEffect(() => {
		if (ref.current) {
			const maxWidth = Math.min(WIDTH, width || ref.current.offsetWidth)
			const maxHeight = fullheight
				? HEIGHT
				: Math.min(HEIGHT, ref.current.offsetHeight)

			setParentWidth(maxWidth)
			setParentHeight(maxHeight - minusHeight)
		}
	}, [ref.current, WIDTH, HEIGHT, height, minusHeight, width])

	let footerTable: any = () => {
		return (
			<TableFooter
				footer={footer}
				fold={fold}
				totalCount={data?.totalCount}
				coinglobal={coinglobal}
				dataSource={dataSource}
			/>
		)
	}

	const dateurl = datesearch
		? `${(startDate && `&startdate=${startDate}`) || ''}
	${(endDate && `&enddate=${endDate}`) || ''}`
		: ''

	let KEY = apikey
		? apikey + dateurl + '&maxData=' + maxData + '&page=' + page
		: null

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(
				`${KEY}`,
				token + '',
				{},
				false,
				coinglobal == 'C' ? API_COINURL : null
			)

			console.log(
				'Table API',
				coinglobal == 'C' ? API_COINURL : null,
				response
			)

			actionrefresh && actionrefresh()
			return response?.result?.result
		},
		autorefresh
			? {
					revalidateOnFocus: false,
					dedupingInterval: 0,
					refreshInterval: autorefresh,
			  }
			: { revalidateOnFocus: false, dedupingInterval: 0 }
	)

	useEffect(() => {
		KEY = apikey
			? apikey + dateurl + '&maxData=' + maxData + '&page=' + page
			: null

		mutate()
	}, [page, coinglobal])

	let dataSource = data?.list || []

	if (onlyonline) {
		dataSource = dataSource.filter(item => item?.client)
	}

	useEffect(() => {
		if (realtime) {
			const EPS  = new Set()
			
			dataSource?.map((item: any) => {
				if(item?.position?.length > 0) {
					item?.position?.map((pos: any) => {
						EPS.add(pos?.exchange_pairs_id)
					})
				}

				if(item?.exchange_pairs_id) {
					EPS.add(item?.exchange_pairs_id)
				}
			})

			EPS.forEach((ep_id: any) => {
				joinGroup('users', ep_id)
			})
		}
	}, [dataSource, realtime])

	return (
		<Card
			key={'card' + KEY}
			ref={ref}
			title={title}
			extra={
				<Space size="small">
					{extra}
					{datesearch && (
						<Space.Compact>
							<DatePicker
								placeholder="시작일"
								allowClear={true}
								onChange={e =>
									setStartDate(
										e
											? dayjs(e, 'YYYY-MM-DD').format(
													'YYYY-MM-DD'
											  )
											: null
									)
								}
								defaultValue={
									startDate
										? dayjs(startDate, 'YYYY-MM-DD')
										: undefined
								}
							/>
							<DatePicker
								placeholder="종료일"
								allowClear={true}
								onChange={e =>
									setEndDate(
										e
											? dayjs(e, 'YYYY-MM-DD').format(
													'YYYY-MM-DD'
											  )
											: null
									)
								}
								defaultValue={
									endDate
										? dayjs(endDate, 'YYYY-MM-DD')
										: undefined
								}
							/>
						</Space.Compact>
					)}

					{detail && (
						<Button
							onClick={() => {
								goTo(nav, detail)
							}}
							type={undefined}
							size="small">
							<OrderedListOutlined />
						</Button>
					)}

					{!hideIcon && (
						<>
							<Button
								onClick={() => {
									mutate(1)
								}}
								type={undefined}
								size="small">
								<ReloadOutlined />
							</Button>
						</>
					)}

					{closeAction && (
						<Button
							onClick={() => {
								closeAction()
							}}
							style={{
								paddingInline: 10,
								marginLeft: 10,
							}}
							type={undefined}
							size="small">
							<IconX />
						</Button>
					)}

					{action && action(mutate)}
					{actionmodal && actionmodal(mutate)}
				</Space>
			}
			size="small"
			style={{
				width: '100%',
				height: '100%',
				margin: 0,
				padding: 0,
			}}>
			<CanvasWrap>
				<CanvasTable
					dataSource={dataSource}
					width={parentWidth}
					maxheight={parentHeight}
					rowAction={rowAction}
					selectedid={selectedid}
					scale={scale}
					topSum={topSum}
					columns={columns(
						mutate,
						data => {
							setSelectedData(data)
							setShowModal(true)
						},
						token,
						fold,
						shareMutate,
						getPrice,
						getRate,
						krwrate,
						EPs,
						coinglobal,
						selectedid
					)}
				/>
				{footerTable()}
			</CanvasWrap>
		</Card>
	)
}

export default React.memo(CanvasTableAPI)

export const CanvasWrap = styled.div`
	display: flex;
	flex-wrap: wrap;

	& canvas {
		font-smooth: never;
		-webkit-font-smoothing: none;
	}

	& .rowsncolumns-grid-scrollbar {
		overflow: auto !important;
	}

	& .rowsncolumns-grid-scrollbar-x {
		overflow: auto !important;
		display: block !important;
	}

	& *::-webkit-scrollbar {
		width: 12px;
		height: 12px;
		background-color: #6e6e6e;
	}
	& *::-webkit-scrollbar-thumb {
		background-color: #444;
		border-radius: 10px;
		box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25);
	}
	& *::-webkit-scrollbar-track {
		background-color: #dddddd;
	}

	& *::-webkit-scrollbar-button:single-button {
		background-color: #333;
		display: block;
		border-style: solid;
		height: 13px;
		width: 13px;
		cursor: pointer;
	}
	/* Up */
	& *::-webkit-scrollbar-button:single-button:vertical:decrement {
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #dfdfdf transparent;
	}
	& *::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
		border-color: transparent transparent #ffffff transparent;
	}
	/* Down */
	& *::-webkit-scrollbar-button:single-button:vertical:increment {
		border-width: 8px 8px 0 8px;
		border-color: #dfdfdf transparent transparent transparent;
	}

	& *::-webkit-scrollbar-button:vertical:single-button:increment:hover {
		border-color: #ffffff transparent transparent transparent;
	}

	/* Left */
	& *::-webkit-scrollbar-button:single-button:horizontal:decrement {
		border-width: 8px 8px 8px 0;
		border-color: transparent #dfdfdf transparent transparent;
	}

	& *::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
		border-color: transparent #ffffff transparent transparent;
	}
	/* Right */
	& *::-webkit-scrollbar-button:single-button:horizontal:increment {
		border-width: 8px 0 8px 8px;
		border-color: transparent transparent transparent #dfdfdf;
	}

	& *::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
		border-color: transparent transparent transparent #ffffff;
	}
`

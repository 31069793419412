import { useExchangeStore } from 'app/hooks/trade/exchangeStore'
import React from 'react'
import { Text } from 'styles/canvas'

const CanvasContracts = ({
	data,
	created_at = undefined,
	showtrade = false,
	p = { x: 0, y: 0, width: 100, height: 25 },
}) => {
	const { EPs } = useExchangeStore(true)
	const EP = EPs?.find(e => e.id === data?.exchange_pairs_id)

	if (!data) return null
	data.contract = EP?.groupname

	return (
		<Text
			x={p.x}
			y={p.y}
			width={p.width}
			height={p.height}
			fontSize={10}
			fontWeight={700}
			align={'center'}
			wrap={'word'}>
			{`${EP?.groupname}${
				showtrade ? ` ${data?.long_short == 'L' ? '매수' : '매도'}` : ''
			}`}
		</Text>
	)
}

export default React.memo(CanvasContracts)

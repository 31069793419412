import BigNumber from 'bignumber.js'

/* eslint-disable no-useless-escape */

export const typeFromLevel = (level: number) => {
	switch (level) {
		case 0:
			return '신규회원'
		case 1:
			return '일반회원'
		case 2:
			return 'VIP'
		case 3:
			return 'VVIP'
		case 5:
			return '파트너회원'
		case 6:
			return '모의회원'
		case 7:
			return '페이크회원'
		case 8:
			return '운영자'
		case 9:
			return '총관리자'
		case -1:
			return '검토회원'
		case -2:
			return '거절회원'
		case -8:
			return '모의대기'
		case -9:
			return '블랙회원'
		case -10:
			return '모의블랙'
		case 99:
			return '개발'
		default:
			return '일반회원'
	}
}
export const typeFromLevelSimple = (level: number) => {
	switch (level) {
		case 0:
			return '신규'
		case 1:
			return '일반'
		case 2:
			return 'VIP'
		case 3:
			return 'VVIP'
		case 5:
			return '파트너'
		case 6:
			return '모의'
		case 7:
			return '페이크'
		case 8:
			return '운영자'
		case 9:
			return '총관리자'
		case -1:
			return '검토'
		case -2:
			return '거절'
		case -8:
			return '모의대기'
		case -9:
			return '블랙'
		case -10:
			return '모의블랙'
		case 99:
			return '개발'
		default:
			return '일반'
	}
}


export const typeFromLevelPartner = (level: number) => {
	switch (level) {
		case 0:
			return '승인대기'
		case 1:
			return '거래가능'
		case 2:
			return '거래가능'
		case 3:
			return '거래가능'
		case 5:
			return '파트너회원'
		case 6:
			return '모의회원'
		case 7:
			return '페이크회원'
		case 8:
			return '운영자'
		case 9:
			return '총관리자'
		case -1:
			return '승인대기'
		case -2:
			return '접속불가'
		case -8:
			return '모의대기'
		case -9:
			return '접속불가'
		case -10:
			return '접속불가'
		case 99:
			return '개발'
		default:
			return '일반회원'
	}
}


export default class CIUtils {
	static stockid(id: number) {
		let n: string = id + ''
		return n.length >= 6 ? n : new Array(6 - n.length + 1).join('0') + n
	}

	static stockidFromResult(result: any) {
		if (!result) return result

		result.map((item: any) => {
			this.stockid(item.id)
			return result
		})

		return result
	}
	static validateEmail = email => {
		const regex =
			/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/
		return regex.test(email)
	}

	static removeWhitespace = text => {
		const regex = /\s/g
		return text.replace(regex, '')
	}

	static padId(id: number) {
		let n: string = id + ''
		return (
			'43' +
			(n.length >= 6 ? n : new Array(6 - n.length + 1).join('0') + n)
		)
	}
}

export const getPercentageFromPrice = (
	price: number,
	min: number,
	max: number
) => {
	return Math.ceil(((price - min) / (max - min)) * 100)
}

export const padDP = (number: number, dp: number) => {
	BigNumber.clone() // copy config to a new instance

	try {
		if (dp == undefined) dp = 1
		const n = new BigNumber(number)
		return n.toFormat(dp)
	} catch (e) {
		console.error(e)
		return number.toFixed(dp)
	}
}

export const toShortNumber = value => {
	const number = new BigNumber(value)
	let shortNumber: any = number

	if (number.isGreaterThanOrEqualTo(1e12)) {
		shortNumber = number.dividedBy(1e12).toFixed(2) + 'T'
	} else if (number.isGreaterThanOrEqualTo(1e9)) {
		shortNumber = number.dividedBy(1e9).toFixed(2) + 'B'
	} else if (number.isGreaterThanOrEqualTo(1e6)) {
		shortNumber = number.dividedBy(1e6).toFixed(2) + 'M'
	} else if (number.isGreaterThanOrEqualTo(1e3)) {
		shortNumber = number.dividedBy(1e3).toFixed(2) + 'K'
	} else {
		shortNumber = number.toFormat(3)
	}

	return shortNumber?.toString()
}

export const maskNumber = (number: number | string, isMask: boolean) => {
	if (isMask) return number

	// 숫자를 문자열로 변환
	const strNumber = number?.toString()

	// 문자열의 첫 번째 숫자와 마지막 숫자를 제외하고, 나머지 숫자를 "*"로 대체
	const maskedNumber =
		'*'.repeat(strNumber?.length - 1) +
		strNumber?.substring(strNumber?.length - 1)

	return maskedNumber
}

export function copyTextToClipboard(text) {
	var textArea = document.createElement('textarea')
	textArea.value = text

	// Avoid scrolling to bottom
	textArea.style.top = '0'
	textArea.style.left = '0'
	textArea.style.position = 'fixed'

	document.body.appendChild(textArea)
	textArea.focus()
	textArea.select()

	try {
		var successful = document.execCommand('copy')
		var msg = successful ? 'successful' : 'unsuccessful'
		console.log('Copying text command was ' + msg)
	} catch (err) {
		console.log('Oops, unable to copy')
	}

	document.body.removeChild(textArea)
}

export const isIos = () => {
	const userAgent = navigator.userAgent.toLowerCase()
	return /iphone|ipad|ipod/.test(userAgent)
}

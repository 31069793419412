import { Select } from 'antd'
import { useUserGroup } from 'app/hooks/usergroups'
import React from 'react'
import styled from 'styled-components'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const GroupFilter = ({ group, setGroup, width='auto' }) => {
	const { theme } = React.useContext(ThemeContext)

	const { type1 } = useUserGroup()
	return (
		<View
			style={{
				backgroundColor: theme.COLORS.descBox,
				flexDirection: 'row',
				padding: '2px 2px',
				borderRadius: 5,
				gap: 2,
				width: width,
				justifyContent: 'flex-start',
			}}>
			<Text
				style={{
					fontWeight: 'bold',
					fontSize: 11,
					color: theme.COLORS.textw,
					backgroundColor: theme.COLORS.descBox,
					borderRadius: 5,
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					padding: '4px 2px',
				}}>
				그룹
			</Text>
			<GroupWrapper
				style={{
					flexDirection: 'row',
					color: theme.COLORS.textw,
					backgroundColor: theme.COLORS.background,
					borderRadius: 5,
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					padding: '2px 2px',
				}}>
				<Select
					defaultValue={'*'}
					onChange={e => {
						setGroup(e)
					}}
					value={group}
					style={{
						minWidth: 150,
						height: 23,
						padding: '0 0 0 0px',
					}}
					options={[
						{ value: '*', label: '그룹전체' },
						...(type1 || []),
					]}
				/>
			</GroupWrapper>
		</View>
	)
}

export default React.memo(GroupFilter)

const GroupWrapper = styled(View)`

	.ant-select-selector {
		height: 23px !important;
		padding: 0 0 0 0px;
	}
`
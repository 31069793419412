/* eslint-disable eqeqeq */
import { Button, Card, Space, Tabs, Tag, Tooltip } from 'antd'
import { useWindowDimensions } from 'app/hooks'
import React, { useEffect } from 'react'
import { Text, View } from 'styles/reactnative'
import { COINHIDE } from '../constants/APIKEYS'
import { useCoinGlobal } from '../hooks/stateStore/coinGlobal'
import { getSetting, useSetting } from '../hooks/user/mysetting'
import CalcList from '../organisms/Header/CalcList'
import DWList from '../organisms/Header/DWList'
import TradeList from '../organisms/Header/TradeList'
import EntryTime from './EntryTime'
import ScreenShot from './ScreenShot'
import TableAPI from './TableAPI'
import { Meta, MetaTitle, MetaView, TagWrap } from './styles'
import TranslateText from 'app/organisms/Header/TranslateText'
import KrwPrice from './KrwPrice'
import { COLORS } from 'app/constants'
import { typeFromLevel } from 'utils/ciutils'
import BigNumber from 'bignumber.js'
import PartnerTradeList from 'app/organisms/Header/PartnerTradeList'

export const buttonlist = {
	주문접수: ['GT001', 'GT000'],
	주문체결: ['GT005'],
	주문취소: ['ST004', 'GT003', 'GT100'],
	로스컷: ['GT999'],
	접속: ['HM001', 'LOGINTRY', 'FR008'],
	전체프론트: [
		'ST001',
		'ST002',
		'ST004',
		'ST005',
		'ST006',
		'ST007',
		'ST008',
		'ST009',
		'SO003',
		'GT006',
		'FR001',
		'FR002',
		'FR003',
		'FR004',
		'FR005',
		'SO001',
		'FR008',
	],
	MIT: ['MIT001', 'MIT002', 'MIT003'],
	손익절: ['ST005', 'ST007', 'FR005', 'ST006'],
}

const UserInfoTabs = ({ data, fold }) => {
	const { width } = useWindowDimensions()

	const { data: coinglobal, mutate: mutateCG } = useCoinGlobal()

	const [logfilter, setLogfilter] = React.useState<any>('')
	const [log, setLog] = React.useState<any>(new Set([]))

	const { data: setting } = useSetting(1)
	const PRT = getSetting(setting, 'PRT', false)
	const PIP = getSetting(setting, 'PIP', false)
	const PAT = getSetting(setting, 'PAT', false)

	useEffect(() => {
		if (log.size == 0) {
			setLogfilter('')
		} else {
			const temp = Array.from(log)
				.map((key: any) => buttonlist[key])
				.flat()
			setLogfilter(temp.join(','))
		}
	}, [log])

	const tabs: any = [
		{
			title: '회원정보',
			content: (
				<Card
					style={{
						width: '100%',
						height: '100%',
						maxWidth: 500,
						margin: 0,
						padding: 0,
						background: '#f1f1f1',
					}}>
					<TagWrap style={{ marginTop: 0, marginBottom: 10 }}>
						<Tag>
							예수금
							<KrwPrice
								price={data?.balance}
								coinglobal={coinglobal}
							/>
						</Tag>
						<Tag>
							누적거래량
							<KrwPrice
								price={data?.total}
								won={'계약'}
								coinglobal={coinglobal}
							/>
						</Tag>
						<Tag>
							누적수수료
							<KrwPrice
								price={-data?.feetotal}
								coinglobal={coinglobal}
							/>
						</Tag>
					</TagWrap>

					<MetaView>
						<MetaTitle>
							<Text>{'회원등급'}</Text>
						</MetaTitle>
						<Meta
							style={{
								background: 'transparent',
								padding: 0,
							}}>
							{typeFromLevel(data?.level)}
						</Meta>
					</MetaView>
					<MetaView>
						<MetaTitle>
							<Text>{'그룹'}</Text>
						</MetaTitle>
						<Meta style={{ background: 'transparent', padding: 0 }}>
							<>
								{data?.group ? (
									<View>
										{data?.group
											?.split(',')
											.map((item, index) => (
												<Button>
													{item?.split('|')[1]}
												</Button>
											))}
									</View>
								) : (
									<Text>-</Text>
								)}
							</>
						</Meta>
					</MetaView>
					<MetaView>
						<MetaTitle>
							<Text>{'닉네임'}</Text>
						</MetaTitle>
						<Meta>
							<Text>{data?.nickname}</Text>
						</Meta>
					</MetaView>

					<MetaView>
						<MetaTitle>
							<Text>{'실명'}</Text>
						</MetaTitle>
						<Meta>
							<Text>{data?.fullname}</Text>
						</Meta>
					</MetaView>
					<MetaView>
						<MetaTitle>
							<Text>{'아이디'}</Text>
						</MetaTitle>
						<Meta>
							<Text>{data?.loginid}</Text>
						</Meta>
					</MetaView>

					<MetaView>
						<MetaTitle>
							<Text>{'보유예수금'}</Text>
						</MetaTitle>
						<Meta style={{ justifyContent: 'space-between' }}>
							<Text>
								{BigNumber(data?.balance || 0).toFormat(0)}
							</Text>
						</Meta>
					</MetaView>
					<MetaView>
						<MetaTitle>
							<Text>{'초대코드'}</Text>
						</MetaTitle>
						<Meta>
							<Text>{data?.referral?.code}</Text>
						</Meta>
						<Meta>
							<Text>수익비 {data?.referral?.setting}%</Text>
						</Meta>
					</MetaView>

					<MetaView>
						<MetaTitle>
							<Text>{'가입시코드'}</Text>
						</MetaTitle>
						<Meta
							style={{
								background: 'transparent',
								padding: 0,
							}}>
							{data?.referral_code}
						</Meta>
					</MetaView>

					<MetaView>
						<MetaTitle>
							<Text>{'입금가상계좌'}</Text>
						</MetaTitle>
						<Meta>
							<Text>{data?.vaccount}</Text>
						</Meta>
					</MetaView>
					<MetaView>
						<MetaTitle>
							<Text>{'출금계좌'}</Text>
						</MetaTitle>
						<Meta>
							<Text>
								{data?.bank_name} {data?.bank_account}
							</Text>
						</Meta>
					</MetaView>
				</Card>
			),
		},
		{
			title: '거래내역',
			content: <PartnerTradeList userid={data?.id} />,
		},
		{
			title: '일일손익',
			content: <CalcList user_id={data?.id} partner={true} />,
		},
		{
			title: '입출금내역',
			content: <DWList user_id={data?.id} />,
		},
	]

	if (PRT) {
		tabs.push({
			title: '화면보기',
			content: (
				<TableAPI
					apikey={'/group/sclist?user_id=' + data?.id}
					columns={mutate => [
						{
							title: '시간',
							dataIndex: 'created_at',
							key: 'created_at',
							align: 'center',
							render: (text, record) => {
								return (
									<EntryTime
										time={record?.created_at}
										offset={9}
									/>
								)
							},
						},
						{
							title: '보기',
							dataIndex: 'data',
							key: 'data',
							align: 'center',
							render: (text, record) => {
								return <ScreenShot data={record} />
							},
						},
					]}
				/>
			),
		})
	}

	if (PIP) {
		tabs.push({
			title: 'IP내역',
			content: (
				<TableAPI
					apikey={'/group/userip?user_id=' + data?.id}
					columns={mutate => {
						return [
							{
								title: '시간',
								dataIndex: 'created_at',
								key: 'created_at',
								align: 'center',
								render: (text, record) => {
									return (
										<EntryTime
											time={record?.created_at}
											offset={9}
										/>
									)
								},
							},
							{
								title: 'IP',
								dataIndex: 'ip',
								key: 'ip',
								align: 'ip',
								render: (text, record) => {
									return <Text>{text}</Text>
								},
							},
						]
					}}
				/>
			),
		})
	}

	if (PAT) {
		tabs.push({
			title: '활동로그',
			content: (
				<TableAPI
					extra={
						<>
							<Button
								type={log.size == 0 ? 'primary' : 'default'}
								danger
								size="small"
								style={{ marginLeft: 10 }}
								onClick={() => setLog(new Set([]))}>
								전체로그
							</Button>
							{buttonlist &&
								Object.keys(buttonlist)?.map(key => (
									<Button
										type={
											log?.has(key)
												? 'primary'
												: 'default'
										}
										size="small"
										onClick={() => {
											if (log?.has(key)) {
												setLog((prev: any) => {
													const temp = new Set(prev)
													temp.delete(key)
													return temp
												})
											} else {
												setLog((prev: any) => {
													const temp = new Set(prev)
													temp.add(key)
													return temp
												})
											}
										}}>
										{key}
									</Button>
								))}
						</>
					}
					apikey={
						'/group/history/users?log=' +
						logfilter +
						'&type=user_id&searchWord=' +
						data?.id
					}
					columns={mutate => {
						return [
							{
								title: '시간',
								dataIndex: 'created_at',
								key: 'created_at',
								align: 'center',
								width: 140,
								render: (text, record) => {
									return (
										<EntryTime time={record?.created_at} 
										offset={9}/>
									)
								},
							},
							{
								title: '프론트시간',
								dataIndex: 'time',
								key: 'time',
								width: 140,
								align: 'left',
								render: (text, record) => {
									try {
										const parsed = JSON.parse(record?.data)
										if (parsed?.time) {
											return (
												<EntryTime
													time={parsed?.time}
													offset={0}
												/>
											)
										}
									} catch (e) {}
								},
							},
							{
								title: '내역',
								dataIndex: 'log',
								key: 'log',
								align: 'left',
								render: (text, record) => {
									return (
										<TranslateText
											text={text}
											data={record}
										/>
									)
								},
							},
							{
								title: '비고',
								dataIndex: 'data',
								align: 'center',
								width: 150,
								render: (text, record) => {
									let print = <></>

									if (record?.log.includes('GT')) {
										const split = record?.data?.split(':')
										if (split?.length > 1) {
											if (split[8] == '1') {
												print = <Text>청산</Text>
											}
											if (split[8] == '2') {
												print = <Text>익절</Text>
											}
											if (split[8] == '4') {
												print = <Text>전종목청산</Text>
											}
											if (split[8] == '3') {
												print = <Text>현종목청산</Text>
											}
											if (split[8] == '6') {
												print = <Text>손절</Text>
											}
											if (split[7] == '1') {
												print = <Text>MIT</Text>
											}
											if (split[7] == '10') {
												print = <Text>F10</Text>
											}
											if (split[7] == '11') {
												print = <Text>F11</Text>
											}
										}
									} else if (record?.log.includes('MIT')) {
										print = <Text>MIT실행</Text>
									}

									try {
										const parsed = JSON.parse(record?.data)

										if (parsed?.window) {
											print = (
												<>
													{print}
													<Tag color="orange">
														{parsed?.window}
													</Tag>
												</>
											)
										}

										let version = <></>

										if (parsed?.version) {
											version = (
												<Tag
													color="green"
													style={{ maxWidth: 50 }}>
													v{parsed?.version}
												</Tag>
											)
										}

										if (
											parsed?.isMobile ||
											parsed?.device == 'MTS'
										) {
											return (
												<TagWrap>
													{version}
													<Tag
														color="red"
														style={{
															maxWidth: 50,
														}}>
														모바일
													</Tag>
													{print}
												</TagWrap>
											)
										} else if (parsed?.device == 'HTS') {
											return (
												<TagWrap>
													{version}
													<Tag
														color="blue"
														style={{
															maxWidth: 50,
														}}>
														HTS
													</Tag>
													{print}
												</TagWrap>
											)
										}

										return (
											<TagWrap>
												{version}
												{print}
											</TagWrap>
										)
									} catch (e) {
										return (
											<TagWrap>
												{print}
												<Text>{!print && text}</Text>
											</TagWrap>
										)
									}
								},
							},
							{
								title: '아이피',
								dataIndex: 'ip',
								key: 'ip',
								width: 80,
								align: 'center',
								render: (text, record) => {
									return (
										<Text
											style={{
												fontSize: 9,
												letterSpacing: 0.8,
											}}>
											{text}
										</Text>
									)
								},
							},

							{
								title: '상세',
								dataIndex: 'data',
								key: 'data',
								align: 'center',
								width: 80,
								render: (text, record) => {
									return (
										<Tooltip title={text}>
											<Text
												style={{
													fontSize: 9,
													whiteSpace: 'nowrap',
													wordBreak: 'break-all',
													wordWrap: 'normal',
													display: 'block',
													width: 70,
													textOverflow: 'ellipsis',
													overflow: 'hidden',
												}}>
												{text}
											</Text>
										</Tooltip>
									)
								},
							},
						]
					}}
				/>
			),
		})
	}

	const ChildrenComp = id => {
		return tabs[id].content
	}
	if (data?.id == undefined) return null

	return (
		<View
			style={{
				width: width < 600 ? '100%' : fold ? '77%' : '68%',
				flexDirection: 'column',
			}}>
			<Space
				style={{
					position: 'absolute',
					right: 80,
					top: 20,
					zIndex: 2000,
				}}>
				{!COINHIDE && (
					<>
						<Button
							type={coinglobal == 'G' ? 'primary' : 'default'}
							danger
							onClick={() => {
								mutateCG('G')
							}}>
							해선
						</Button>
						<Button
							type={coinglobal == 'C' ? 'primary' : 'default'}
							danger
							onClick={() => {
								mutateCG('C')
							}}>
							코인
						</Button>
					</>
				)}
			</Space>
			<Tabs
				defaultActiveKey="0"
				tabPosition={'top'}
				style={{ minHeight: 400, width: '100%', padding: 0 }}
				items={new Array(tabs.length).fill(null).map((_, i) => {
					const id = String(i)
					return {
						label: `${tabs[i]?.title}`,
						key: id,
						disabled: i === 28,
						children: ChildrenComp(id),
					}
				})}
			/>
		</View>
	)
}

export default React.memo(UserInfoTabs)
